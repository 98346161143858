import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function ProductionBar({ data }) {

  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    if (data) {
      setFilteredData(data?.data?.data);
    }
  }, [data?.data?.data]);

  const labels = filteredData?.cropsPlanted
    ? filteredData.cropsPlanted.map((item) => item.name)
    : [];


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      // title: {
      //   display: true,
      //   text: 'Crop per Acre',
      // },
    },
  };



  const BarData = {
    labels,
    datasets: [
      {
        label: 'Types of crop in acre',
        data: filteredData?.cropsPlanted
          ? filteredData.cropsPlanted.map((item) => item.acersPlanted)
          : [],
        backgroundColor: '#8CBE41',
        height: '100%'
      },

    ],
  };


  return <Bar options={options} data={BarData} />;
}
