import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";

import {

  faHandshake,
  faWallet,
  faHashtag,
  faFileInvoice,

  faMoneyCheck,

} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IoMdApps } from "react-icons/io";
import { MdSubscriptions } from "react-icons/md";
import { useSelector } from "react-redux";
import Config from "../../Config";
import { FaDollarSign } from "react-icons/fa";
import { GoIssueClosed } from "react-icons/go";
import { FaWheelchair } from "react-icons/fa";
import { FaBriefcaseMedical, FaCalendarPlus } from "react-icons/fa";

import {

  FaBusinessTime,

  FaBoxTissue,
  FaUser,
  FaUserPlus,
  FaWarehouse,
  FaBuilding,
  FaTruck,
} from "react-icons/fa";
import { MdOutlineMyLocation, MdProductionQuantityLimits } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import { MdVerified } from "react-icons/md";

import { GiWheat, GiSlicedBread } from "react-icons/gi";
import { SiMarketo } from "react-icons/si";
import { AiOutlineStock } from "react-icons/ai";
const Summary = (data) => {
  const user = useSelector((state) => state.UserReducer.user);
  const [summaryData, setSummaryData] = useState({
    GHC: <FaDollarSign />,
    icon: <FontAwesomeIcon icon={faHandshake} />,
    revenueicon: <FontAwesomeIcon icon={faWallet} />,
    ussdicon: <FontAwesomeIcon icon={faHashtag} />,
    billingicon: <FontAwesomeIcon icon={faFileInvoice} />,
    paymenticon: <FontAwesomeIcon icon={faMoneyCheck} />,
    todayicon: <FaBriefcaseMedical />,
    weekicon: <FaBuilding />,
    appicon: <IoMdApps />,
    attend: <FaBusinessTime />,
    pulse: <FaUser />,
    subicon: <MdSubscriptions />,
    upcom: <FaWheelchair />,
    missapp: <FaWarehouse />,
    addUser: <FaUserPlus />,
    verify: <MdVerified />,
    remind: <IoIosNotifications />,
    track: <MdOutlineMyLocation />,
    production: <MdProductionQuantityLimits />,
    raw: <GiWheat />,
    processed: <GiSlicedBread />,
    marketing: <SiMarketo />,
    stock: <AiOutlineStock />,
    transport: <FaTruck />,
    issueraw: <FaBoxTissue />,
    issuepro: <GoIssueClosed />,
  });


  const [filteredData, setFilteredData] = useState({});
  useEffect(() => {
    setFilteredData(data?.data?.data?.data || {});
  });

  return (
    <BookingSummary>
      <BookingGrid>
        {filteredData?.card &&
          filteredData?.card.map((product, index) => {
            if (
              (user.userType === Config.userType.SUPERADMIN && (product.name === Config.dashboardTypes.BILLING || product.name === Config.dashboardTypes.Partners || product.name === Config.dashboardTypes.PAYMENTS || product.name === Config.dashboardTypes.SUB_UNSUB)) ||
              (user.userType === Config.userType.ADMIN && (product.name === Config.dashboardTypes.Partners || product.name === Config.dashboardTypes.PAYMENTS || product.name === Config.dashboardTypes.USSD || product.name === Config.dashboardTypes.Apps || product.name === Config.dashboardTypes.Revenue || product.name === Config.dashboardTypes.Total_Marketings || product.name === Config.dashboardTypes.Stock || product.name === Config.dashboardTypes.Transport || product.name === Config.dashboardTypes.Raw_Material || product.name === Config.dashboardTypes.Issue_Raw_Material || product.name === Config.dashboardTypes.Processed_Material || product.name === Config.dashboardTypes.Issue_Product)) ||
              (user.userType === Config.userType.FIELDAGENT && (product.name === Config.dashboardTypes.USSD || product.name === Config.dashboardTypes.Apps || product.name === Config.dashboardTypes.Revenue || product.name === Config.dashboardTypes.Total_Marketings || product.name === Config.dashboardTypes.Stock || product.name === Config.dashboardTypes.Transport)) ||
              (user.userType === Config.userType.WAREHOUSEMANAGER && (product.name === Config.dashboardTypes.Raw_Material || product.name === Config.dashboardTypes.Processed_Material || product.name === Config.dashboardTypes.Issue_Raw_Material || product.name === Config.dashboardTypes.Issue_Product))
            ) {

              return (
                <Card
                  key={product.id}
                  name={product.name}
                  type={product.name}
                  count={product.count}
                  balance={product.balance}
                  successcount={product.success}
                  GHC={summaryData.GHC}
                  icon={summaryData.icon}
                  pulse={summaryData.pulse}
                  addUser={summaryData.addUser}
                  attendicon={summaryData.attend}
                  revenueicon={summaryData.revenueicon}
                  ussdicon={summaryData.ussdicon}
                  missapp={summaryData.missapp}
                  marketing={summaryData.marketing}
                  billingicon={summaryData.billingicon}
                  todayicon={summaryData.todayicon}
                  weekicon={summaryData.weekicon}
                  paymenticon={summaryData.paymenticon}
                  appicon={summaryData.appicon}
                  stock={summaryData.stock}
                  productionicon={summaryData.production}
                  processed={summaryData.processed}
                  upcom={summaryData.upcom}
                  subicon={summaryData.subicon}
                  verify={summaryData.verify}
                  remind={summaryData.remind}
                  track={summaryData.track}
                  failedcount={product.failed}
                  miss_count={product.miss_count}
                  new_count={product.new_count}
                  attend_count={product.attend_count}
                  pending_count={product.pending_count}
                  subcount={product.sub_count}
                  unsubcount={product.unsub_count}
                  liveapps={product.live_apps}
                  sandboxapps={product.sandbox_apps}
                  totalapps={product.total_count}
                  raw={summaryData.raw}
                  issueraw={summaryData.issueraw}
                  issuepro={summaryData.issuepro}
                  transport={summaryData.transport}
                  className={`text-white`}

                  colorIndex={index}
                />
              );
            }


          })}
      </BookingGrid>
    </BookingSummary>
  );
};

// ...

const Card = ({

  missapp,
  raw,
  pulse,
  productionicon,
  processed,
  issueraw,
  transport,
  issuepro,
  marketing,
  stock,
  name,
  count,
  balance,
  className,

  todayicon,
  weekicon,

  appicon,

  colorIndex,
  type,
}) => {

  const cardColors = [
    "#FFD8BE",
    "#C7C7FF",
    "#A9ECBF",
    "#F3BBE1",
    "#79DBFA",

    "#e5e7eb",
  ];
  const iconColor = [
    "#FCA147",
    "#9595FF",
    "#43C16E",
    "#DC5BB7",
    "#007499",

    "#4b5563",
  ];

  const textColor = [
    "#fb850f",
    "#6060f5",
    "#15a546",
    "#e932b5",
    "#007499",

    "#4b5563",
  ];

  const [isHovered, setIsHovered] = useState(false);
  const cardIndex = colorIndex % cardColors.length;
  const iconIndex = colorIndex % iconColor.length;
  const textIndex = colorIndex % textColor.length;
  const color = cardColors[cardIndex];
  const iconcolor = iconColor[iconIndex];
  const textcolor = textColor[textIndex];

  const leftPortionStyle = {
    backgroundColor: color,
  };
  const textStyle = {
    color: iconcolor,
    borderColor: iconcolor,

  };
  const textColorStyle = {
    color: textcolor,
    borderColor: textcolor,
  };
  const isClickable = type !== Config.dashboardTypes.Revenue;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      <Box
        style={{
          ...leftPortionStyle,
        }}
        className={`${className} 
     
       }`}

        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="md:h-40 md:mt-10 ">
          {type == Config.dashboardTypes.Raw_Material && (
            <Icon style={textStyle} >{raw}</Icon>
          )}
          {type == Config.dashboardTypes.Processed_Material && (
            <Icon style={textStyle} >
              {processed}
            </Icon>
          )}
          {type == Config.dashboardTypes.Partners && (
            <Icon style={textStyle} >{missapp}</Icon>
          )}
          {type == Config.dashboardTypes.Revenue && (
            <Icon style={textStyle} >
              {productionicon}
            </Icon>
          )}
          {type == Config.dashboardTypes.USSD && (
            <Icon style={textStyle}>{appicon}</Icon>
          )}
          {type == Config.dashboardTypes.BILLING && (
            <Icon style={textStyle}>{weekicon}</Icon>
          )}
          {type == Config.dashboardTypes.PAYMENTS && (
            <Icon style={textStyle}>{todayicon}</Icon>
          )}
          {type == Config.dashboardTypes.Apps && (
            <Icon style={textStyle}>{pulse}</Icon>
          )}
          {type == Config.dashboardTypes.SUB_UNSUB && (
            <Icon style={textStyle}>{pulse}</Icon>
          )}
          {type == Config.dashboardTypes.Total_Marketings && (
            <Icon style={textStyle}>{marketing}</Icon>
          )}
          {type == Config.dashboardTypes.Stock && (
            <Icon style={textStyle}>{stock}</Icon>
          )}
          {type == Config.dashboardTypes.Transport && (
            <Icon style={textStyle}>{transport}</Icon>
          )}
          {type == Config.dashboardTypes.Issue_Product && (
            <Icon style={textStyle}>{issuepro}</Icon>
          )}
          {type == Config.dashboardTypes.Issue_Raw_Material && (
            <Icon style={textStyle}>{issueraw}</Icon>
          )}

          <div className=" w-full ">
            <BoxCount className={className}>
              <Count>


                <span>{count < 10 && count != 0 ? `0${count}` : count}</span>

              </Count>
            </BoxCount>
            <BoxTitle style={textColorStyle}>
              {name}

            </BoxTitle>
          </div>
          {/* {type === Config.dashboardTypes.Raw_Material && (

            <BoxCounts className={className}>
              <CountsRight style={textColorStyle}>
               
                Remaining Balance:{" "}
                {balance < 10 && balance != 0
                  ? `0${balance}`
                  : balance} kg
              </CountsRight>
            </BoxCounts>)}
          {type === Config.dashboardTypes.Processed_Material && (

            <BoxCounts className={className}>
              <CountsRight style={textColorStyle}>
               
                Remaining Balance:{" "}
                {balance < 10 && balance != 0
                  ? `0${balance}`
                  : balance} kg
              </CountsRight>
            </BoxCounts>)} */}

        </div>
      </Box>
    </>
  );
};

const BookingSummary = tw.div`  `;

const BookingGrid = tw.div`grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6  mt-10  gap-4 text-center`;

const BoxTitle = tw.h3`font-medium text-lg flex justify-center text-white  capitalize mb-3 `;
const Box = tw.div` w-full  relative md:h-64 grid  rounded-2xl box-border p-2 shadow-md `;
const BoxCount = tw.p`font-medium text-4xl    flex  space-x-1  text-white justify-center my-3  `;
const BoxCounts = tw.span`font-light text-xs   flex    text-white justify-center my-1  `;
const Icon = tw.div`text-white text-4xl mt-4 flex items-center justify-center`;
const CountsRight = tw.span`flex w-full justify-center `;
const Count = tw.span`flex  `;

export default Summary;
