import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";
import { useSelector } from "react-redux";
const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  const getGender = (selectedData) => {
    if (selectedData.gender == 1) {
      return <>Male</>;
    } else {
      return <>Female</>;
    }
  };
  const getType = (selectedData) => {
    if (selectedData.buyerType == 1) {
      return <>Consumer</>;
    }
    else if (selectedData.buyerType == 2) {
      return <>Trade</>;
    } else {
      return <>In-House</>;
    }
  };
  const user = useSelector((state) => state.UserReducer.user);
  return (
    <Model width={`w-11/12 max-w-xl`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title><MdViewHeadline className="mt-1" />View Details</Title>
        <SingleItem name={"Product Name"} value={selectedData?.productName} />

        <SingleItem name={"Destination"} value={selectedData?.destination} />

        <SingleItem name={"Quantity Issued Grade A (kg)"} value={selectedData?.quantityissuedGradeA} /><SingleItem name={"Quantity Issued Grade B (kg)"} value={selectedData?.quantityissuedGradeB} />
        {user.companyName == "Kimolo Super Rice Ltd" && (
          <><SingleItem name={"Quantity Issued Grade C (kg)"} value={selectedData?.quantityissuedGradeC} /><SingleItem name={"Quantity Issued Grade D (kg)"} value={selectedData?.quantityissuedGradeD} /></>)}
        <SingleItem name={"Vehicle Number"} value={selectedData?.vehicleNumber} />

        <SingleItem name={"Vehicle Type"} value={selectedData?.vehiclename} />
        {/* <SingleItem name={"Crop Name"} value={selectedData?.cropName} /> */}

        <SingleItem name={"Vehicle Capacity"} value={selectedData?.vehiclecapacity} />
        <SingleItem name={"Buyer Name"} value={selectedData?.buyerName} />

        <SingleItem name={"Gender"} value={getGender(selectedData) || "N/A"} />
        <SingleItem name={"Phone"} value={selectedData?.phone} />

        <SingleItem name={"Buyer Type"} value={getType(selectedData) || "N/A"} />
        <SingleItem name={"Receipt"} value={selectedData?.receipt} />
        <SingleItem name={"Issued Date"} value={moment(selectedData?.issueDate).format("DD-MM-YYYY hh:mm A")} />

      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    {<Value>{value}</Value>}
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-56`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;

export default ViewDetailModel;
