import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import Config from "./Config";
import moment from "moment";

import Login from "./Pages/LoginPage";
import DashBoard from "./Pages/Admin/DashBoard";
import Users from "./Pages/Admin/Users";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./Pages/Profile";
import AddProduction from "./Pages/Admin/AddProducion";

import RegisterPage from "./Pages/RegisterPage";

import ForgotPasswordPage from "./Pages/ForgotPassword";
import OtpPage from "./Pages/Otp";
import { useIdleTimer } from "react-idle-timer";

import "./i18";
import { Remove_User } from "./Redux/actions";
import FieldAgents from "./Pages/Admin/FieldAgents";
import WarehouseManager from "./Pages/Admin/WarehouseManager";
import Farmers from "./Pages/Admin/Farmer";
import Production from "./Pages/Admin/Production";
import Harvesting from "./Pages/Admin/Harvesting";
import EditProduction from "./Pages/Admin/EditProduction";
import RawMaterial from "./Pages/WarehouseManager/RawMaterial";
import ProcessedMaterial from "./Pages/WarehouseManager/ProcessedMaterial";
import Company from "./Pages/Admin/Company";
import Marketing from "./Pages/FieldAgent/Marketing";
import StockManagement from "./Pages/FieldAgent/StockManagement";
import Transportation from "./Pages/FieldAgent/Transportation";
import Distribution from "./Pages/WarehouseManager/Distribution";
import StockManagementSelling from "./Pages/WarehouseManager/StockManagementSelling";
import Report from "./Pages/FieldAgent/Report";
import AddStockManagement from "./Pages/FieldAgent/AddStockManagement";
import EditStockManagement from "./Pages/FieldAgent/EditStockmanagement";
import AddRawMaterial from "./Pages/WarehouseManager/AddRawMaterial";
import EditRawMaterial from "./Pages/WarehouseManager/EditRawMaterial";
import AddProcessedMaterial from "./Pages/WarehouseManager/AddProcessedMaterial";
import EditProcessedMaterial from "./Pages/WarehouseManager/EditProcessedMaterial";
import Vehicle from "./Pages/Vehicle/Vehicle";
import IssueRawMaterial from "./Pages/WarehouseManager/IssueRawMaterial";
import IssueRawMaterialList from "./Pages/WarehouseManager/IssueRawMaterialList";
import EditIssueRawMaterial from "./Pages/WarehouseManager/EditIssueRawMaterial";
import IssueProductList from "./Pages/WarehouseManager/IssueProductList";
import EditIssueProcessedProduct from "./Pages/WarehouseManager/EditIssueProcessedProduct";
import IssueProcessedProduct from "./Pages/WarehouseManager/IssueProcessedProduct";

function App() {
  const user = useSelector((state) => state.UserReducer.user);

  const dispatch = useDispatch();

  const isSessionExpired = () => {
    if (user) {
      const expireAt = user?.expireAt;
      const currentTime = moment().valueOf();
      const isExpired = moment(currentTime).isAfter(expireAt);
      if (isExpired) {
        window.location.href = "/";
        dispatch(Remove_User());
      }
    }
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle: () => isSessionExpired(),
    timeout: 1000 * 60 * Config.idleTime,
  });

  useEffect(() => isSessionExpired(), []);

  const UserType =
    user?.userType == 1
      ? "SUPERADMIN"
      : user?.userType == 2
      ? "ADMIN"
      : user?.userType == 3
      ? "FIELDAGENT"
      : user?.userType == 4
      ? "WAREHOUSE"
      : "USER";
  //console.log(user);
  if (!user)
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />
        <Routes>
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/get-otp" element={<OtpPage />} />
          <Route path="*" index element={<Login />} />
        </Routes>
      </>
    );

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />

      <Routes>
        <Route path="/login" element={<Login />} />

        {/* SUPERADMIN */}
        {user?.userType == 1 && (
          <>
            <Route path="/" element={<DashBoard />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/companies" element={<Company />} />
            <Route path="/field-agents" element={<FieldAgents />} />
            <Route path="/warehouse-manager" element={<WarehouseManager />} />

            <Route path="/profile" element={<Profile />} />
          </>
        )}

        {/* ADMIN */}
        {user?.userType == 2 && (
          <>
            <Route path="/" element={<DashBoard />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/field-agents" element={<FieldAgents />} />
            <Route path="/warehouse-manager" element={<WarehouseManager />} />

            <Route path="/profile" element={<Profile />} />
          </>
        )}

        {/* Filed Agent */}
        {user?.userType == 3 && (
          <>
            <Route path="/" element={<DashBoard />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/farmers" element={<Farmers />} />
            <Route path="/production-records" element={<Production />} />
            <Route
              path="/add-production/:companyId"
              element={<AddProduction />}
            />
            <Route path="/edit-production/:id" element={<EditProduction />} />
            <Route path="/harvesting" element={<Harvesting />} />
            <Route path="/marketing" element={<Marketing />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/stock-management" element={<StockManagement />} />
            <Route
              path="/add-stock-management/:companyId"
              element={<AddStockManagement />}
            />
            <Route
              path="/edit-stock-management/:id"
              element={<EditStockManagement />}
            />
            <Route path="/vehicle" element={<Vehicle />} />
            <Route path="/transportation" element={<Transportation />} />
            <Route path="/report" element={<Report />} />
          </>
        )}

        {/* Warehouse Manager */}
        {user?.userType == 4 && (
          <>
            <Route path="/" element={<DashBoard />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/field-agents" element={<FieldAgents />} />
            <Route path="/warehouse-manager" element={<WarehouseManager />} />
            <Route path="/raw-material" element={<RawMaterial />} />
            <Route path="/processes-product" element={<ProcessedMaterial />} />
            <Route path="/distribution" element={<Distribution />} />
            <Route
              path="/stock-management-selling"
              element={<StockManagementSelling />}
            />
            <Route path="/add-raw-material" element={<AddRawMaterial />} />
            <Route
              path="/edit-raw-material/:id"
              element={<EditRawMaterial />}
            />
            <Route path="/vehicle" element={<Vehicle />} />
            <Route
              path="/add-processed-product/:companyId"
              element={<AddProcessedMaterial />}
            />
            <Route
              path="/edit-processed-product/:id"
              element={<EditProcessedMaterial />}
            />
            <Route
              path="/issue-raw-material-list"
              element={<IssueRawMaterialList />}
            />
            <Route
              path="/edit-issue-raw-material/:id"
              element={<EditIssueRawMaterial />}
            />
            <Route
              path="/issue-raw-material/:companyId"
              element={<IssueRawMaterial />}
            />

            <Route path="/issue-product-list" element={<IssueProductList />} />
            <Route
              path="/edit-issue-product/:id"
              element={<EditIssueProcessedProduct />}
            />
            <Route
              path="/issue-product/:companyId"
              element={<IssueProcessedProduct />}
            />
            <Route path="/profile" element={<Profile />} />
          </>
        )}
      </Routes>

      {/* <Footer /> */}
    </>
  );
}

export default App;
