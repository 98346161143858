import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";

import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import CustomPhoneInput from "../CountryCode";
const AddVehicleModel = ({
  AddSubAgentLoading,
  postAddVehicleUser,

  setOpenUploadModel,

  data,
  // companyArr,
}) => {

  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  console.log(data)
  const SubmitHandler = (values) => {

    postAddVehicleUser(values);
  };



  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add Vehicle Detail</Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              {/* <CombineInputGroup> */}
              <InputGroup>
                <Label htmlFor="number">Vehicle Number</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="number"
                    id="number"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>

              <InputGroup>
                <Label htmlFor="type">Vehicle Type</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="type"
                    id="type"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="capacity">Vehicle Capacity (ton)</Label>
                <FieldWrapper>
                  <Field
                    type="number"
                    name="capacity"
                    id="capacity"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              {/* </CombineInputGroup> */}



            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;


const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

export default AddVehicleModel;
