import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const CustomPhoneInput = ({ field, form, ...props }) => {
    const [phone, setPhone] = useState(field.value || "");
    // const [phone, setPhone] = useState((typeof field.value === 'string' ? field.value : ''));
    // console.log(phone)
    const handleChange = (value) => {
        setPhone(value);
        form.setFieldValue(field.name, value);
    };

    return (
        <>
            <PhoneInput
                country={"tz"}
                {...props}
                // value={phone}
                value={phone.toString()} // Convert to string
                onChange={handleChange}
                inputProps={{
                    name: field.name,
                    id: field.name,
                    required: true,
                    autoFocus: true,
                    className: "w-full h-10 border border-gray-300 rounded-md pl-12",
                }}
                countryCodeEditable={false}
            />
        </>
    );
};
export default CustomPhoneInput