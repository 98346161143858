import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import Summary from "../../Components/DashBoard/Summary";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Field, Form, Formik } from "formik";
import Navbar from "../../Components/Navbar";
import { PieChart } from "../../Components/DashBoard/PieChart";
import { Underline } from "../../Components/Styles/PageStyles";

import {
  FieldWrapper,
  InputGroup,
  Label,
} from "../../Components/Styles/InputStyles";
import Images from "../../Images";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";

import { useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

import { Doughnuts } from "../../Components/DashBoard/Doughnuts";
import { PolarChart } from "../../Components/DashBoard/PolarChart";
import { RadarChart } from "../../Components/DashBoard/RadarChart";
import { TransportDoughnut } from "../../Components/DashBoard/TransportDoughnut";
import { ProductionBar } from "../../Components/DashBoard/ProductionBar";
import { HarvestingLine } from "../../Components/DashBoard/HarvestingLine";
import { MarketingArea } from "../../Components/DashBoard/MarketingArea";
import { StockBar } from "../../Components/DashBoard/StockBar";
import { TransportLine } from "../../Components/DashBoard/TransportLine";
import { RawMaterialBar } from "../../Components/DashBoard/RawMaterial";
import { ProcessedProductLine } from "../../Components/DashBoard/ProcessedProductLine";
import { VerticleMarketing } from "../../Components/DashBoard/VerticleMarketing";
import { VerticleHarvesting } from "../../Components/DashBoard/verticleHarvesting";
import { VerticleStock } from "../../Components/DashBoard/verticleStock";
import { VerticleIssueStock } from "../../Components/DashBoard/verticalIssueStock";
import { VerticleReceivedMaterial } from "../../Components/DashBoard/VerticleReceivedMaterial";
import { VerticleIssueMaterial } from "../../Components/DashBoard/verticleIssueMaterial";
import { VerticleReceivedProduct } from "../../Components/DashBoard/verticleReceivedProduct";
import { VerticleIssueProduct } from "../../Components/DashBoard/verticleIssueProduct";

const DashBoard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer.user);
  const [startDate, setStartDate] = useState(
    localStorage.getItem("startDate") ||
      moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    localStorage.getItem("endDate") || moment(new Date()).format("YYYY-MM-DD")
  );

  const GetDashboard = async (values) => {
    const body = new FormData();
    body.append("userType", user?.userType);
    body.append("startDate", values.startDate);
    body.append("endDate", values.endDate);
    body.append("companyId", user?.companyId);
    return await axios.post(`${Config.apiUrl}/dashboard`, values, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getDashboardSuccess = (res) => {};

  const getDashboardError = (res) => {};

  const {
    isLoading: getDashboardLoading,
    mutate: getDashboardMutate,
    error,
    data,
  } = useMutation(GetDashboard, {
    onSuccess: getDashboardSuccess,
    onError: getDashboardError,
  });

  useEffect(() => {
    const body = new FormData();

    body.append("startDate", startDate);
    body.append("endDate", endDate);
    body.append("companyId", user?.companyId);
    getDashboardMutate(body);
  }, [startDate, endDate]);

  const initialValues = {
    startDate: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const submitHandler = (values) => {
    const body = new FormData();

    body.append("startDate", moment(values.startDate).format("YYYY-MM-DD"));
    body.append("endDate", moment(values.endDate).format("YYYY-MM-DD"));
    body.append("companyId", user?.companyId);
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    getDashboardMutate(body);
  };
  console.log(data);

  return (
    <>
      <Navbar />

      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          // height: "100vh",
        }}
        // className="h-screen"
      >
        <Container>
          <BoxHead>
            <BoxTitle className="!mb-0 whitespace-nowrap">
              Summary
              <Underline />
            </BoxTitle>
            {(user.userType === Config.userType.FIELDAGENT ||
              user.userType === Config.userType.WAREHOUSEMANAGER) && (
              <Filters
                submitHandler={submitHandler}
                initialValues={initialValues}
                InitialLoading={getDashboardLoading}
                user={user}
              />
            )}
          </BoxHead>

          {!getDashboardLoading && data && (
            <>
              <Summary data={data} />
              {user.userType === Config.userType.FIELDAGENT && (
                <>
                  <TwoCols>
                    <FirstCol>
                      <BoxTitle className="text-center mt-5 md:mt-0">
                        Production{" "}
                      </BoxTitle>
                      <ProductionBar
                        data={data}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </FirstCol>
                    <FirstCol>
                      <BoxTitle className="text-center mt-5 md:mt-0">
                        Harvesting{" "}
                      </BoxTitle>
                      <VerticleHarvesting
                        data={data}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </FirstCol>
                    <FirstCol>
                      <BoxTitle className="text-center mt-5 md:mt-0">
                        Marketing{" "}
                      </BoxTitle>
                      <VerticleMarketing
                        data={data}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </FirstCol>

                    <FirstCol>
                      <BoxTitle className="text-center mt-5 md:mt-0">
                        Received Stocks{" "}
                      </BoxTitle>
                      <VerticleStock
                        data={data}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </FirstCol>
                    <FirstCol>
                      <BoxTitle className="text-center mt-5 md:mt-0">
                        Issued Stock{" "}
                      </BoxTitle>
                      <VerticleIssueStock
                        data={data}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </FirstCol>
                  </TwoCols>
                </>
              )}
              {user.userType === Config.userType.WAREHOUSEMANAGER && (
                <>
                  <TwoCols>
                    <FirstCol>
                      <BoxTitle className="text-center mt-5 md:mt-0">
                        Received Raw Material{" "}
                      </BoxTitle>
                      <VerticleReceivedMaterial
                        data={data}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </FirstCol>
                    <FirstCol>
                      <BoxTitle className="text-center mt-5 md:mt-0">
                        Issued Raw Material{" "}
                      </BoxTitle>
                      <VerticleIssueMaterial
                        data={data}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </FirstCol>
                    <FirstCol>
                      <BoxTitle className="text-center mt-5 md:mt-0">
                        Received Processed Product{" "}
                      </BoxTitle>
                      <VerticleReceivedProduct
                        data={data}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </FirstCol>
                    <FirstCol>
                      <BoxTitle className="text-center mt-5 md:mt-0">
                        Issued Processed Product{" "}
                      </BoxTitle>
                      <VerticleIssueProduct
                        data={data}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </FirstCol>
                  </TwoCols>
                </>
              )}
            </>
          )}

          {getDashboardLoading && <Loading />}
        </Container>
      </Bg>
    </>
  );
};

const Filters = ({ initialValues, submitHandler, InitialLoading, user }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form>
        <FilterContainer>
          <InputGroup className="max-w-xs ">
            <Label htmlFor="startDate">Start Date:</Label>
            <FieldWrappers className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="startDate" id="startDate" class="ml-1" />
            </FieldWrappers>
          </InputGroup>

          <InputGroup className="max-w-xs ">
            <Label htmlFor="endDate">End Date:</Label>
            <FieldWrappers className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="endDate" id="endDate" className="ml-1" />
            </FieldWrappers>
          </InputGroup>

          <ApplyBtn disabled={InitialLoading} type="submit">
            Apply Filter
          </ApplyBtn>
        </FilterContainer>
      </Form>
    </Formik>
  );
};
const BoxHead = tw.div`flex justify-between items-center mb-10 mx-auto `;

const Container = tw.div`Container `;

const BoxTitle = tw.h1`
${(p) => (p.$fullWidth ? "flex inline-flex" : "justify-center")}
text-2xl  text-gray-700 font-medium  space-x-2 items-center  mb-5  rounded-md cursor-pointer`;
export const FieldWrappers = tw.div`
${(p) => (p.$select ? "" : "pl-2  field-wrapper border border-gray-300")}
 relative  rounded-md   w-full  flex items-center`;

const Bg = tw.section` flex  items-center py-24 md:pt-28 pb-14`;
const FilterContainer = tw.div`
grid grid-cols-2 w-full gap-4 md:flex md:space-x-4 items-end mt-12 md:mt-8`;
const TwoCol = tw.div`my-10 grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-10 `;
const Col = tw.div`  items-center  mx-auto p-10`;
const FirstCol = tw.div`  md:p-10 rounded-md border border-gray-100 shadow-xl `;
const TwoCols = tw.div`my-10 grid md:grid-cols-2  grid-cols-1 gap-10 `;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-custom-green hover:bg-green-700  py-2.5 text-sm`;
export default DashBoard;
