import React, { useEffect, useState } from "react";
import Images from "../Images";
import tw from "tailwind-styled-components";
import Config from "../Config";
import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Save_User, Save_Games_Data } from "../Redux/actions";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import Checkbox from "react-custom-checkbox";
import { BsCheckLg } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import Card from "../Components/Card/LoginCard";
import {
  InputGroup,
  FieldWrapper,
  LoginLabel,
} from "../Components/Styles/InputStyles";
import { useTranslation } from "react-i18next";
import Loading from "react-loading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [selectedLang, setActiveLang] = useState("eng");
  const countryCodes = [
    { value: "+1", label: "+1 (United States)" },
    { value: "+44", label: "+44 (United Kingdom)" },
    { value: "+91", label: "+91 (India)" },
    // Add more options as needed
  ];

  const InitialValue = {
    phone: "",
    password: "",
  };

  //------- Send Login Request -------
  const LoginPostFunction = async (values) => {
    const body = new FormData();
    body.append("phone", values.phone);
    body.append("password", values.password);
    return await axios.post(`${Config.apiUrl}/login`, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (res) => {
    if (res?.data?.status == "OK") {
      const expireAt = moment()
        .add(Config.sessionExpiredTime, "minutes")
        .valueOf();
      const realData = { ...res.data.data, expireAt };
      dispatch(Save_User(realData));
      navigate("/dashboard");
    } else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: postLogin } = useMutation(
    LoginPostFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
  //------- Send Login Request -------

  const SubmitHandler = (values) => {
    if (!values.phone) {
      return toast.error("Please enter phone number");
    } else if (!values.password) {
      return toast.error("Please enter password");
    }
    postLogin({ ...values });
    // navigate("/companies");
  };

  const handleLogin = () => {};

  const languageChangeHandler = (value) => {
    setActiveLang(value);
    i18n.changeLanguage(value);
  };

  const CustomPhoneInput = ({ field, form, ...props }) => {
    const [phone, setPhone] = useState(field.value || "");

    const handleChange = (value) => {
      setPhone(value);
      form.setFieldValue(field.name, value);
    };

    return (
      <>
        <PhoneInput
          country={"tz"}
          {...props}
          value={phone}
          onChange={handleChange}
          inputProps={{
            name: field.name,
            id: field.name,
            required: true,
            autoFocus: true,
            className: "w-full h-10 border border-gray-300 rounded-md pl-12",
          }}
          countryCodeEditable={false}
        />
      </>
    );
  };

  return (
    <Login
      style={{
        backgroundImage: `url(${Images.LoginImage2})`,
        backgroundSize: "cover",
        backgroundPosition: "center right",
      }}
    >
      {/* <LanguageWrapper>
        <LangButton
          $active={selectedLang === "swahili"}
          onClick={() => languageChangeHandler("swahili")}
        >
          Swahili
        </LangButton>
        <LangButton
          $active={selectedLang === "eng"}
          onClick={() => languageChangeHandler("eng")}
        >
          English
        </LangButton>
      </LanguageWrapper> */}
      <TextOverlay>
        <OverlayTitle>
          Procurement and Supply Chain Management System
        </OverlayTitle>
        <OverlayText>
          Streamlining Operations and Enhancing Efficiency
        </OverlayText>
      </TextOverlay>
      <Card>
        <TextWrapperComp
          handleLogin={handleLogin}
          InitialValue={InitialValue}
          SubmitHandler={SubmitHandler}
          isLoadingReg={isLoadingReg}
          countryCodes={countryCodes}
          CustomPhoneInput={CustomPhoneInput}
          t={t}
        />
      </Card>
    </Login>
  );
};

const TextWrapperComp = ({
  handleLogin,
  InitialValue,
  SubmitHandler,
  t,
  isLoadingReg,
  countryCodes,
  CustomPhoneInput,
}) => (
  <TextWrapper>
    <Container>
      <Center>
        <Title>{t("LoginWelcome")}</Title>
        <Formik initialValues={InitialValue} onSubmit={SubmitHandler}>
          {(formikProps) => (
            <>
              <Form className="w-full">
                <FormContainer>
                  <InputGroup>
                    <LoginLabel htmlFor="phone">{t("Phone Number")}</LoginLabel>
                    {/* <PhoneInput
                      value={formikProps.values.phone}
                      onChange={(value) =>
                        formikProps.setFieldValue("phone", value)
                      }
                      inputProps={{
                        name: "phone",
                        id: "phone",
                        required: true,
                        autoFocus: true,
                        className:
                          "w-full h-10 border border-gray-300 rounded-md pl-12",
                      }}
                    /> */}
                    <Field
                      name="phone"
                      render={({ field, form }) => (
                        <CustomPhoneInput className="rounded-md bg-white" field={field} form={form} />
                      )}
                    />
                  </InputGroup>

                  <InputGroup>
                    <LoginLabel htmlFor="password">{t("Password")}</LoginLabel>
                    <FieldWrapper>
                      <Field
                        type="password"
                        name="password"
                        id="password"
                        autoComplete="off"
                      />
                    </FieldWrapper>
                  </InputGroup>
                  <ForgotWrapper>
                    <Link to="/forgot-password">
                      {/* <ForgotPassword> */}
                      {t("ForgotPassword")}
                      {/* </ForgotPassword> */}
                    </Link>
                  </ForgotWrapper>
                  <RowWrapper className="-mt-3">
                    <Checkbox
                      icon={<BsCheckLg className="text-cyan-600 w-2 h-2" />}
                      name="my-input"
                      onChange={(value) => {}}
                      borderColor="#FFF"
                      style={{ cursor: "pointer" }}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: "none",
                        color: "#FFF",
                        fontSize: ".875rem",
                      }}
                      label={t("rememeber")}
                    />
                  </RowWrapper>
                </FormContainer>
                <div className=" mt-10 flex">
                  <SubmitBtn type="submit" disabled={isLoadingReg}>
                    {isLoadingReg ? (
                      <Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                      />
                    ) : (
                      t("login")
                    )}
                  </SubmitBtn>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Center>

      {/* <Link className="md:-mt-10 lg:mt-2 mt-2" to="/register">
        <p className="text-sm text-gray-500 -mt-8 ">
          {t("no_account")}{" "}
          <span className="text-gray-500 hover:text-gray-900 font-medium">
            {t("registar_u")}
          </span>
        </p>
      </Link> */}
    </Container>
  </TextWrapper>
);

// const Select = tw.select`h-10 pr-2 border border-gray-300 rounded-l-md`;

// const Field = tw.input`pl-2 h-10 border border-gray-300 rounded-r-md w-full`;

const TextOverlay = tw.div`hidden lg:block md:absolute md:top-1/3 md:left-0 md:w-full  px-10 transform -translate-y-32 `;
const OverlayTitle = tw.h2`md:text-4xl md:w-4/12 lg:text-5xl lg:w-4/12 text-white lg:leading-tight font-bold`;
const OverlayText = tw.p`px-2 inline-block bg-custom-yellow text-base text-white mt-5 leading-relaxed`;
const FormContainer = tw.div` grid max-w-sm  mt-5 w-full gap-5 md:gap-5`;
const Login = tw.section`h-screen flex  bg-white justify-center items-center`;
const Container = tw.div`flex flex-col h-full w-full justify-center items-start   `;
const TextWrapper = tw.div`h-full relative md:pl-8 -mt-8 w-64 md:w-auto pr-4 md:pr-0`;
const Title = tw.h1`text-xl sm:text-2xl text-white tracking-wider md:-mt-14 -mt-8`;
const Center = tw.div`h-full w-11/12   pt-20 mb-5  flex flex-col   `;
const LoginWrapper = tw.div`flex`;
const PageWrapper = tw.div`text-white text-3xl`;
const LanguageWrapper = tw.div`absolute top-5 right-5 flex space-x-2.5 items-center z-50`;
const RowWrapper = tw.div`flex space-x-2.5 items-center mb-2 justify-between w-full`;
// const ForgotPassword = tw.button`tracking-wider  text-gray-500 text-xs hover:text-gray-900 float-right w-32 md:w-auto`;
const ForgotWrapper = tw.div`-mt-3 flex justify-end tracking-wider  text-white text-xs hover:text-gray-900 w-auto`;
const LangButton = tw.button`
${(p) =>
  p.$active
    ? "bg-cyan-600 text-white"
    : "hover:bg-gray-300 bg-gray-200 text-gray-800"}
px-4 py-2 text-xs  cursor-pointer  rounded-full `;
const SubmitBtn = tw.button`
text-sm -mt-8  w-80 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-white hover:bg-slate-200 text-black-800 rounded-md shadow-md`;
export default LoginPage;
