import React, { useEffect, useState } from "react";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";

import Loading from "../../Components/Loading";
import Navbar from "../../Components/Navbar";
import {
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
} from "../../Components/Styles/InputStyles";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
const EditProduction = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  // const [isProductionFetched, setIsProductionFetched] = useState(false);

  // const EditProduct = async () =>
  //   await axios.get(`${Config.apiUrl}/get-production/${id}`, {
  //     headers: {
  //       Authorization: "Bearer" + " " + user.token,
  //       "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  //     },
  //   });

  // const { data, isLoading: productionLoading } = useQuery(
  //   "get-production",

  //   EditProduct,

  //   {
  //     enabled: !isProductionFetched,
  //   }
  // );
  // console.log("Production Loading:", productionLoading);

  // console.log(data);

  // useEffect(() => {
  //   if (data?.data && !isProductionFetched) {
  //     setIsProductionFetched(true);
  //   }
  // }, [isProductionFetched, data]);
  const [isProductionFetched, setIsProductionFetched] = useState(false);
  const [productionLoading, setProductionLoading] = useState(true);

  const EditProduct = async () => {
    try {
      const response = await axios.get(
        `${Config.apiUrl}/get-production/${id}`,
        {
          headers: {
            Authorization: "Bearer" + " " + user.token,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );

      return response.data; // Assuming your data is in response.data
    } catch (error) {
      // Handle errors here
      console.error("Error fetching production:", error);
      throw error;
    }
  };

  const { data, isLoading: queryLoading } = useQuery(
    "get-production",
    EditProduct,
    {
      enabled: !isProductionFetched,
      onSuccess: () => {
        setProductionLoading(false);
      },
    }
  );

  console.log("Production Loading:", productionLoading);
  console.log("Query Loading:", queryLoading);
  console.log("Data:", data);

  useEffect(() => {
    if (data?.data && !isProductionFetched) {
      setIsProductionFetched(true);
    }
  }, [isProductionFetched, data]);
  const EditProductionFunction = async (values) => {
    const body = new FormData();
    body.append("farmerId", values.farmerId);
    body.append("id", id);
    body.append("farmerName", values.farmerName);
    body.append("grade", values.variety);
    body.append("cropType", values.crop);
    body.append("acersPlanted", values.acres);
    body.append("datePlanted", values.date);
    body.append("prodPractice", values.prodPractice);
    body.append("dap", values.dap);
    body.append("urea", values.urea);
    body.append("pest", values.pest);
    body.append("weddingCount", values.weddingCount);

    return await axios.post(`${Config.apiUrl}/update-production`, body, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (res) => {
    if (res?.data?.status == "OK") {
      toast.success(res?.data?.msg || "Success");
      navigate("/production-records");
    } else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    //setOpenUploadModel(false);
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: postRegister } = useMutation(
    EditProductionFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  const SubmitHandler = (values) => {
    postRegister({ ...values });
  };

  const cropNames =
    user?.cropName?.map((crop) => ({
      value: crop.id,
      label: crop.cropName,
    })) || [];
  console.log(data?.data?.data?.farmerName);

  // const InitialValue = {
  //   id: id,
  //   farmerId: data?.data?.data?.farmerId,
  //   farmerName: data?.data?.data?.farmerName,
  //   variety: data?.data?.data?.grade,
  //   crop: data?.data?.data?.cropType,
  //   cropName: data?.data?.data?.cropName,

  //   acres: data?.data?.data?.acersPlanted,

  //   date: moment(data?.data?.data?.datePlanted).format("YYYY-MM-DD"),
  //   weddingCount: data?.data?.data?.weddingCount,
  //   urea: data?.data?.data?.urea,
  //   dap: data?.data?.data?.dap,
  //   pest: data?.data?.data?.pest,
  //   prodPractice: data?.data?.data?.productionPartices,
  // };
  const InitialValue = {
    id: id,
    farmerId: data?.data?.farmerId,
    farmerName: data?.data?.farmerName,
    variety: data?.data?.grade,
    crop: data?.data?.cropType,
    cropName: data?.data?.cropName,

    acres: data?.data?.acersPlanted,

    date: moment(data?.data?.datePlanted).format("YYYY-MM-DD"),
    weddingCount: data?.data?.weddingCount,
    urea: data?.data?.urea,
    dap: data?.data?.dap,
    pest: data?.data?.pest,
    prodPractice: data?.data?.productionPartices,
  };
  console.log(data?.data);
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        <BoxContainer>
          <div className="flex w-full justify-center items-center">
            <div>
              <Title>Edit Production</Title>
              <Underline />
            </div>
          </div>
          {productionLoading && <Loading />}

          {!productionLoading && (
            <TextWrapper>
              <Formik
                initialValues={InitialValue}
                onSubmit={SubmitHandler}
                enableReinitialize
              >
                <Form>
                  <Wrapper>
                    <InputGroup>
                      <Label htmlFor="farmerName">Farmer Name</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="farmerName"
                          placeholder="Farmer Name"
                          id="farmerName"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="variety">Variety Type</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="variety"
                            placeholder="Variety Type"
                            id="variety"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="crop">Crop Type</Label>
                        <FieldWrapper>
                          <Field
                            name="cropName"
                            id="crop"
                            type="text"
                            autoComplete="off"
                            required
                            disabled
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>

                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="acres">Acres Planted</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="acres"
                            placeholder="acres"
                            id="acres"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="date">Planted Date</Label>
                        <FieldWrapper>
                          <Field
                            type="date"
                            name="date"
                            id="date"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="weddingCount">Weeding Count</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="weddingCount"
                            id="weddingCount"
                            placeholder="Weeding Count"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="urea">
                          Nitrogenous Fertilizer (bags)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="urea"
                            placeholder="Nitrogenous Fertilizer"
                            id="urea"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="dap">Phosphate Fertilizer (bags)</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="dap"
                            id="dap"
                            placeholder="Phosphate Fertilizer"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="pest">Pest/disease control</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="pest"
                            id="pest"
                            placeholder="pest"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>

                    <InputGroup>
                      <Label htmlFor="prodPractice">Production Practice</Label>
                      <FieldWrapper>
                        <Field
                          component="textarea"
                          row="7"
                          type="textarea"
                          name="prodPractice"
                          id="prodPractice"
                          autoComplete="off"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </Wrapper>
                  <UpdateBtn type="submit" disabled={isLoadingReg}>
                    {" "}
                    {isLoadingReg ? (
                      <Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                      />
                    ) : (
                      t("Update")
                    )}
                  </UpdateBtn>
                </Form>
              </Formik>
            </TextWrapper>
          )}
        </BoxContainer>
      </Bg>
    </>
  );
};

const TextWrapper = tw.div`h-full w-full flex justify-center`;

const UpdateBtn = tw.button`
text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
export default EditProduction;
