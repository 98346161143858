import React, { useEffect, useState } from "react";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";

import { useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import Navbar from "../../Components/Navbar";
// import Loading from "react-loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
  CombineInputGridGroup,
  CombineInputTwoGridGroup,
  SubmitBtn,
  CombineInputThreeGridGroup,
} from "../../Components/Styles/InputStyles";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
const EditProcessedMaterial = () => {
  const [cropName, setCropName] = useState("");
  const user = useSelector((state) => state.UserReducer.user);
  const cropNames =
    user?.cropName?.map((crop) => ({
      value: crop.id,
      label: crop.cropName,
    })) || [];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  // const [isProductionFetched, setIsProductionFetched] = useState(false);
  // const EditProduct = async () =>
  //   await axios.get(`${Config.apiUrl}/edit-product/${id}`, {
  //     headers: {
  //       Authorization: "Bearer" + " " + user.token,
  //       "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  //     },
  //   });

  // const { data, isLoading: productionLoading } = useQuery(
  //   "get-production",

  //   EditProduct,
  //   {
  //     enabled: !isProductionFetched,
  //   }
  // );

  // console.log(data);

  // useEffect(() => {
  //   if (data?.data && !isProductionFetched) {
  //     setIsProductionFetched(true);
  //   }
  // }, [isProductionFetched, data]);
  // console.log(data?.data?.data?.source);
  const [isProductionFetched, setIsProductionFetched] = useState(false);
  const [productionLoading, setProductionLoading] = useState(true);

  const EditProduct = async () => {
    try {
      const response = await axios.get(`${Config.apiUrl}/edit-product/${id}`, {
        headers: {
          Authorization: "Bearer" + " " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });

      return response.data; // Assuming your data is in response.data
    } catch (error) {
      // Handle errors here
      console.error("Error fetching production:", error);
      throw error;
    }
  };

  const { data, isLoading: queryLoading } = useQuery(
    "get-production",
    EditProduct,
    {
      enabled: !isProductionFetched,
      onSuccess: () => {
        setProductionLoading(false);
      },
    }
  );

  console.log("Production Loading:", productionLoading);
  console.log("Query Loading:", queryLoading);
  console.log("Data:", data);

  useEffect(() => {
    if (data?.data && !isProductionFetched) {
      setIsProductionFetched(true);
    }
  }, [isProductionFetched, data]);
  //------- Edit Single Entry -------
  const EditFieldAgentFunction = async (values) => {
    const body = new FormData();
    body.append("productId", values.productId);
    if (user.companyName == "Kimolo Super Rice Ltd") {
      body.append("gradec", values.gradec);
      body.append("graded", values.graded);
    }
    body.append("gradea", values.gradea);
    body.append("gradeb", values.gradeb);
    body.append("variety", values.variety);
    body.append("id", id);
    body.append("date", values.date);

    return await axios.post(`${Config.apiUrl}/update-product`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token, //token is not in inspect
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      navigate("/processes-product");
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
  };

  const onEditEntryError = (data) => {
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: editEntryLoading, mutate: editAddUser } = useMutation(
    EditFieldAgentFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );

  console.log(data?.data);

  const InitialValue = {
    gradea: data?.data?.qtyRecivedGradeA,
    gradeb: data?.data?.qtyRecivedGradeB,
    gradec: data?.data?.qtyRecivedGradeC,
    graded: data?.data?.qtyRecivedGradeD,
    variety: data?.data?.variety,
    date: moment(data?.data?.receivingDate).format("YYYY-MM-DD"),

    productId: data?.data?.productId,
    productName: data?.data?.productName,

    id: id,
  };
  const SubmitHandler = (values) => editAddUser({ ...values });
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        <BoxContainer>
          <div className="flex w-full justify-center items-center">
            <div>
              <Title>Edit Processed Product</Title>
              <Underline />
            </div>
          </div>
          {productionLoading && <Loading />}

          {!productionLoading && (
            <TextWrapper>
              <Formik
                initialValues={InitialValue}
                onSubmit={SubmitHandler}
                enableReinitialize
              >
                <>
                  <Form>
                    <Wrapper>
                      <CombineInputThreeGridGroup>
                        <InputGroup>
                          <Label htmlFor="productName">Product Name</Label>
                          <FieldWrapper>
                            <Field
                              name="productName"
                              id="productId"
                              type="text"
                              autoComplete="off"
                              required
                              disabled
                            />
                          </FieldWrapper>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="variety">Variety Type</Label>
                          <FieldWrapper>
                            <Field
                              type="text"
                              name="variety"
                              placeholder="Variety Type"
                              id="variety"
                              autoComplete="off"
                              className="truncate"
                              required
                            />
                          </FieldWrapper>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="date">Receiving Date</Label>
                          <FieldWrapper>
                            <Field
                              type="date"
                              name="date"
                              id="date"
                              autoComplete="off"
                              className="truncate"
                              required
                            />
                          </FieldWrapper>
                        </InputGroup>
                      </CombineInputThreeGridGroup>

                      <CombineInputTwoGridGroup>
                        <InputGroup>
                          <Label htmlFor="gradea">
                            Quantity Received Grade A(kg)
                          </Label>
                          <FieldWrapper>
                            <Field
                              type="number"
                              name="gradea"
                              id="gradea"
                              autoComplete="off"
                              className="truncate"
                              required
                            />
                          </FieldWrapper>
                        </InputGroup>

                        <InputGroup>
                          <Label htmlFor="gradeb">
                            Quantity Received Grade B(kg)
                          </Label>
                          <FieldWrapper>
                            <Field
                              type="number"
                              name="gradeb"
                              id="gradeb"
                              autoComplete="off"
                              className="truncate"
                              required
                            />
                          </FieldWrapper>
                        </InputGroup>
                        {user.companyName == "Kimolo Super Rice Ltd" && (
                          <>
                            <InputGroup>
                              <Label htmlFor="gradec">
                                Quantity Received Grade C(kg)
                              </Label>
                              <FieldWrapper>
                                <Field
                                  type="number"
                                  name="gradec"
                                  id="gradec"
                                  autoComplete="off"
                                  className="truncate"
                                  required
                                />
                              </FieldWrapper>
                            </InputGroup>

                            <InputGroup>
                              <Label htmlFor="graded">
                                Quantity Received Grade D(kg)
                              </Label>
                              <FieldWrapper>
                                <Field
                                  type="number"
                                  name="graded"
                                  id="graded"
                                  autoComplete="off"
                                  className="truncate"
                                  required
                                />
                              </FieldWrapper>
                            </InputGroup>
                          </>
                        )}
                      </CombineInputTwoGridGroup>
                    </Wrapper>
                    <BtnWrapper>
                      <SubmitBtn type="submit" disabled={editEntryLoading}>
                        {" "}
                        {editEntryLoading ? (
                          <Loading
                            color="white"
                            width={20}
                            height={20}
                            noPadding={true}
                          />
                        ) : (
                          t("Update")
                        )}
                      </SubmitBtn>
                    </BtnWrapper>
                  </Form>
                </>
              </Formik>
            </TextWrapper>
          )}
        </BoxContainer>
      </Bg>
    </>
  );
};

const TextWrapper = tw.div`h-full w-full flex justify-center`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const UpdateBtn = tw.button`
text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;

export default EditProcessedMaterial;
