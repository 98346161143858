import React, { useEffect, useState } from "react";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";
import CustomPhoneInput from "../../Components/CountryCode";
import { useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import Navbar from "../../Components/Navbar";
import Loading from "react-loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
  CombineInputTwoGridGroup,
  CombineInputGridGroup,
  SubmitBtn,
  CombineInputThreeGridGroup,
} from "../../Components/Styles/InputStyles";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import { useTranslation } from "react-i18next";
// import Loading from "../../Components/Loading";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const IssueProcessedProduct = () => {
  const [cropName, setCropName] = useState("");
  const user = useSelector((state) => state.UserReducer.user);
  const cropNames =
    user?.cropName?.map((crop) => ({
      value: crop.id,
      label: crop.cropName,
    })) || [];
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { companyId } = useParams();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const GetProduct = async () =>
    await axios.get(`${Config.apiUrl}/getProduct/${companyId}`, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });

  const {
    isLoading: InitialLoading,
    data,
    error,
    refetch,
    isRefetching,
  } = useQuery("get-farmer", GetProduct, {
    enabled: !isDataFetched,
  });
  console.log(data);

  useEffect(() => {
    if (data?.data && !isDataFetched) {
      setIsDataFetched(true);
    }
  }, [isDataFetched, data]);

  //------- Add Single Entry -------
  const AddFarmerPostFunction = async (values) => {
    const body = new FormData();

    body.append("productId", values.productId);
    if (user.companyName == "Kimolo Super Rice Ltd") {
      body.append("issuegradec", values.issuegradec);
      body.append("issuegraded", values.issuegraded);
    }
    body.append("capacity", selectedVehicle.capacity);
    body.append("number", values.number);
    body.append("issuegradea", values.issuegradea);
    body.append("issuegradeb", values.issuegradeb);
    body.append("type", selectedVehicle.type);
    body.append("buyerName", values.buyerName);
    body.append("variety", values.variety);
    body.append("gender", values.gender);
    body.append("phone", values.phone);
    body.append("receipt", values.receipt);
    body.append("buyerType", values.buyerType);

    body.append("destination", values.destination);

    body.append("issueDate", values.issueDate);

    body.append("companyId", user.companyId);

    return await axios.post(`${Config.apiUrl}/issue-product`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddUserSuccess = (data) => {
    console.log(data);
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      navigate("/issue-product-list");
    } else {
      toast.error(data?.data?.msg);
    }
  };

  const onAddUserError = (data) => {
    toast.error(data?.response?.data?.msg || "An Error Occurred");
  };

  const { isLoading: AddSubAgentLoading, mutate: postFarmerUser } = useMutation(
    AddFarmerPostFunction,
    {
      onSuccess: onAddUserSuccess,
      onError: onAddUserError,
    }
  );

  const SubmitHandler = (values) => {
    const cropTypeData = data?.data?.data?.finalProduct?.find(
      (crop) => crop.productId === values.productId
    );
    console.log(cropTypeData);
    if (cropTypeData) {
      const sumGradeA = parseInt(cropTypeData.sumGradeA);
      const sumGradeB = parseInt(cropTypeData.sumGradeB);
      const sumGradeC = parseInt(cropTypeData.sumGradeC);
      const sumGradeD = parseInt(cropTypeData.sumGradeD);

      const issueGradeA = parseInt(values.issuegradea) || 0;
      const issueGradeB = parseInt(values.issuegradeb) || 0;
      const issueGradeC = parseInt(values.issuegradec) || 0;
      const issueGradeD = parseInt(values.issuegraded) || 0;
      console.log(sumGradeA);
      console.log(issueGradeA);
      if (
        issueGradeA > sumGradeA ||
        issueGradeB > sumGradeB ||
        issueGradeC > sumGradeC ||
        issueGradeD > sumGradeD
      ) {
        toast.error(
          "Quantity Issued values cannot exceed the quantity received values."
        );
        return;
      }
    }
    postFarmerUser({ ...values });
  };
  const ProductList = data?.data?.data?.finalProduct?.map((item) => ({
    value: item.productId,
    label: item.productName,
  }));
  const InitialValue = {
    destination: "",

    issuegradea: "",
    issuegradeb: "",
    issuegradec: "",
    issuegraded: "",
    variety: "",
    number: "",
    type: "",
    capacity: "",
    buyerName: "",
    gender: "",
    phone: "",
    buyerType: "",
    receipt: "",

    issueDate: "",
    id: "",
    productId: "",
    productName: "",
  };
  const genderOption = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];
  const buyerType = [
    { value: "1", label: "Consumer" },
    { value: "2", label: "Trade" },
    { value: "3", label: "In-House" },
  ];
  const vehicleList = data?.data?.data?.vehicles?.map((item) => ({
    value: item.id,
    label: item.number,
    type: item.type,
    capacity: item.capacity,
  }));
  console.log(data?.data);
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        <BoxContainer>
          <div className="flex w-full justify-center items-center">
            <div>
              <Title>Issue Processed Product</Title>
              <Underline />
            </div>
          </div>
          <TextWrapper>
            <Formik initialValues={InitialValue} onSubmit={SubmitHandler}>
              <>
                <Form>
                  <Wrapper>
                    <CombineInputThreeGridGroup>
                      <InputGroup>
                        <Label htmlFor="productId">Product Name</Label>
                        <FieldWrapper $select={true}>
                          <Field
                            name="productId"
                            id="productId"
                            autoComplete="off"
                          >
                            {(props) => (
                              <Select
                                className="w-full h-full"
                                required
                                options={ProductList}
                                classNamePrefix="select"
                                onChange={(val) => {
                                  props.form.setFieldValue(
                                    "productId",
                                    val.value
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="variety">Variety Type</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="variety"
                            placeholder="Variety Type"
                            id="variety"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="destination">Destination</Label>
                        <FieldWrapper>
                          <Field
                            name="destination"
                            id="destination"
                            type="text"
                            autoComplete="off"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputThreeGridGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="issueDate">Issued Date</Label>
                        <FieldWrapper>
                          <Field
                            type="date"
                            name="issueDate"
                            id="issueDate"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>

                    <CombineInputTwoGridGroup>
                      <InputGroup>
                        <Label htmlFor="issuegradea">
                          Quantity Issued Grade A(kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="issuegradea"
                            id="issuegradea"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="issuegradeb">
                          Quantity Issued Grade B(kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="issuegradeb"
                            id="issuegradeb"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      {user.companyName == "Kimolo Super Rice Ltd" && (
                        <>
                          <InputGroup>
                            <Label htmlFor="issuegradec">
                              Quantity Issued Grade C(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="issuegradec"
                                id="issuegradec"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>

                          <InputGroup>
                            <Label htmlFor="issuegradead">
                              Quantity Issued Grade D(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="issuegraded"
                                id="issuegraded"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>
                        </>
                      )}
                    </CombineInputTwoGridGroup>

                    {/* <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="number">Vehicle Number</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="number"
                            id="number"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="type">Vehicle Type</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="type"
                            id="type"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="capacity">Vehicle Capacity(ton)</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="capacity"
                            id="capacity"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup> */}
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="number">Vehicle Number</Label>
                        <FieldWrapper $select={true}>
                          <Field
                            required
                            name="number"
                            id="number"
                            autoComplete="off"
                          >
                            {(props) => (
                              <Select
                                className="w-full h-full"
                                required
                                options={vehicleList}
                                classNamePrefix="select"
                                // onChange={(val) => {
                                //   props.form.setFieldValue("type", val.value);
                                // }}
                                onChange={(val) => {
                                  props.form.setFieldValue("number", val.value);
                                  setSelectedVehicle(val);
                                }}
                                // onChange={(val) => {
                                //   const selectedVehicle = vehicleList.find(
                                //     (vehicle) => vehicle.value === val.value
                                //   );
                                //   props.form.setFieldValue("type", val.value);
                                //   setSelectedVehicle(selectedVehicle);
                                // }}
                              />
                            )}
                          </Field>
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="type">Vehicle Type</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="type"
                            id="type"
                            autoComplete="off"
                            className="truncate"
                            required
                            value={selectedVehicle ? selectedVehicle.type : ""}
                            readOnly
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="capacity">Vehicle Capacity(ton)</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="capacity"
                            id="capacity"
                            autoComplete="off"
                            className="truncate"
                            required
                            value={
                              selectedVehicle ? selectedVehicle.capacity : ""
                            }
                            readOnly
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="buyerName">Buyer Name</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="buyerName"
                            id="buyerName"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="gender">Gender</Label>
                        <FieldWrapper $select={true}>
                          <Field
                            required
                            name="gender"
                            id="gender"
                            autoComplete="off"
                          >
                            {(props) => (
                              <Select
                                className="w-full h-full"
                                required
                                options={genderOption}
                                classNamePrefix="select"
                                onChange={(val) => {
                                  props.form.setFieldValue("gender", val.value);
                                }}
                              />
                            )}
                          </Field>
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="phone">Phone Number</Label>
                        <Field
                          name="phone"
                          render={({ field, form }) => (
                            <CustomPhoneInput field={field} form={form} />
                          )}
                        />
                        {/* <FieldWrapper>
                    <Field
                      type="number"

                      maxlength={9}
                      name="phone"
                      id="phone"
                      autoComplete="off"
                      className="truncate"

                    />
                  </FieldWrapper> */}
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="buyerType">Type of buyer</Label>
                        <FieldWrapper $select={true}>
                          <Field
                            required
                            name="buyerType"
                            id="buyerType"
                            autoComplete="off"
                          >
                            {(props) => (
                              <Select
                                className="w-full h-full"
                                required
                                options={buyerType}
                                classNamePrefix="select"
                                onChange={(val) => {
                                  props.form.setFieldValue(
                                    "buyerType",
                                    val.value
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="receipt">Receipt</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="receipt"
                            id="receipt"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                  </Wrapper>
                  <BtnWrapper>
                    <SubmitBtn type="submit" disabled={AddSubAgentLoading}>
                      {" "}
                      {AddSubAgentLoading ? (
                        <Loading
                          color="white"
                          width={20}
                          height={20}
                          noPadding={true}
                        />
                      ) : (
                        t("Add")
                      )}
                    </SubmitBtn>
                  </BtnWrapper>
                </Form>
              </>
            </Formik>
          </TextWrapper>
        </BoxContainer>
      </Bg>
    </>
  );
};

const TextWrapper = tw.div`h-full w-full flex justify-center`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const UpdateBtn = tw.button`
text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;

export default IssueProcessedProduct;
