import React, { useEffect, useState } from "react";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";

import { useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import Navbar from "../../Components/Navbar";
import Loading from "react-loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
  CombineInputTwoGridGroup,
  CombineInputGridGroup,
  SubmitBtn,
} from "../../Components/Styles/InputStyles";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import { useTranslation } from "react-i18next";
// import Loading from "../../Components/Loading";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const AddStockManagement = () => {
  const [cropName, setCropName] = useState("");
  const user = useSelector((state) => state.UserReducer.user);
  const cropNames =
    user?.cropName?.map((crop) => ({
      value: crop.id,
      label: crop.cropName,
    })) || [];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { companyId } = useParams();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const GetProduct = async () =>
    await axios.get(`${Config.apiUrl}/get-crops/${companyId}`, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });

  const {
    isLoading: InitialLoading,
    data,
    error,
    refetch,
    isRefetching,
  } = useQuery("get-farmer", GetProduct, {
    enabled: !isDataFetched,
  });
  console.log(data);

  useEffect(() => {
    if (data?.data && !isDataFetched) {
      setIsDataFetched(true);
    }
  }, [isDataFetched, data]);

  //------- Add Single Entry -------
  const AddFarmerPostFunction = async (values) => {
    const body = new FormData();
    body.append("companyId", user.companyId);
    body.append("cropType", values.cropType);
    if (user.companyName == "Kimolo Super Rice Ltd") {
      body.append("gradec", values.gradec);
      body.append("graded", values.graded);

      // body.append("balancec", values.balancec);
      // body.append("balanced", values.balanced);

      // body.append("quantityissuedGradec", values.quantityissuedGradec);
      // body.append("quantityissuedGraded", values.quantityissuedGraded);
    }
    body.append("gradea", values.gradea);
    body.append("gradeb", values.gradeb);
    // body.append("quantityissuedGradeA", values.quantityissuedGradeA);
    // body.append("quantityissuedGradeB", values.quantityissuedGradeB);
    // body.append("balance", values.balance);
    // body.append("balanceb", values.balanceb);
    body.append("variety", values.variety);
    body.append("source", values.source);
    body.append("destination", values.destination);
    body.append("recvdate", values.recvdate);
    // body.append("issueddate", values.issueddate);
    return await axios.post(`${Config.apiUrl}/create-stock`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddUserSuccess = (data) => {
    console.log(data);
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      navigate("/stock-management");
    } else {
      toast.error(data?.data?.msg);
    }
  };

  const onAddUserError = (data) => {
    toast.error(data?.response?.data?.msg || "An Error Occurred");
  };

  const { isLoading: AddSubAgentLoading, mutate: postFarmerUser } = useMutation(
    AddFarmerPostFunction,
    {
      onSuccess: onAddUserSuccess,
      onError: onAddUserError,
    }
  );
  const SubmitHandler = (values) => {
    postFarmerUser({ ...values });
  };

  console.log(data?.data);
  const cropsList = data?.data?.data?.map((item) => ({
    value: item.cropType,
    label: item.cropName,
  }));
  const InitialValue = {
    source: "",
    gradea: "",
    gradeb: "",
    gradec: "",
    graded: "",

    // quantityissuedGradeA: "",
    // quantityissuedGradeB: "",
    // quantityissuedGradec: "",
    // quantityissuedGraded: "",

    // destination: "",
    // balance: "",
    // balanceb: "",
    // balancec: "",
    // balanced: "",

    id: "",
    cropType: "",
    cropName: "",
    recvdate: "",
    // issueddate: "",
  };
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        <BoxContainer>
          <div className="flex w-full justify-center items-center">
            <div>
              <Title>Add Stock</Title>
              <Underline />
            </div>
          </div>
          <TextWrapper>
            <Formik initialValues={InitialValue} onSubmit={SubmitHandler}>
              <>
                <Form>
                  <Wrapper>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="cropType">Crop Name</Label>
                        <FieldWrapper $select={true}>
                          <Field
                            name="cropType"
                            id="cropType"
                            autoComplete="off"
                          >
                            {(props) => (
                              <Select
                                className="w-full h-full"
                                required
                                options={cropsList}
                                classNamePrefix="select"
                                onChange={(val) => {
                                  props.form.setFieldValue(
                                    "cropType",
                                    val.value
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="variety">Variety Type</Label>
                        <FieldWrapper>
                          <Field
                            type="text"
                            name="variety"
                            placeholder="Variety Type"
                            id="variety"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="source">Source</Label>
                        <FieldWrapper>
                          <Field
                            name="source"
                            id="source"
                            type="text"
                            autoComplete="off"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="recvdate">Receiving Date</Label>
                        <FieldWrapper>
                          <Field
                            type="date"
                            name="recvdate"
                            id="recvdate"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                    <CombineInputTwoGridGroup>
                      <InputGroup>
                        <Label htmlFor="gradea">
                          Quantity Received Grade A(kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="gradea"
                            id="gradea"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="gradeb">
                          Quantity Received Grade B(kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="gradeb"
                            id="gradeb"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      {user.companyName == "Kimolo Super Rice Ltd" && (
                        <>
                          <InputGroup>
                            <Label htmlFor="gradec">
                              Quantity Received Grade C(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="gradec"
                                id="gradec"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>

                          <InputGroup>
                            <Label htmlFor="graded">
                              Quantity Received Grade D(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="graded"
                                id="graded"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>
                        </>
                      )}
                    </CombineInputTwoGridGroup>

                    {/* <CombineInputGridGroup>
                      <InputGroup>
                        <Label htmlFor="quantityissuedGradeA">
                          Quantity Issued Grade A(kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="quantityissuedGradeA"
                            id="quantityissuedGradeA"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="quantityissuedGradeB">
                          Quantity Issued Grade B(kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="quantityissuedGradeB"
                            id="quantityissuedGradeB"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      {user.companyName == "Kimolo Super Rice Ltd" && (
                        <>
                          <InputGroup>
                            <Label htmlFor="quantityissuedGradec">
                              Quantity Issued Grade C(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="quantityissuedGradec"
                                id="quantityissuedGradec"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>

                          <InputGroup>
                            <Label htmlFor="quantityissuedGraded">
                              Quantity Issued Grade D(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="quantityissuedGraded"
                                id="quantityissuedGraded"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>
                        </>
                      )}
                    </CombineInputGridGroup>

                    <CombineInputGridGroup>
                      <InputGroup>
                        <Label htmlFor="balance">
                          Balance Quantity Grade A (kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="balance"
                            id="balance"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="balanceb">
                          Balance Quantity Grade B (kg)
                        </Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="balanceb"
                            id="balanceb"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      {user.companyName == "Kimolo Super Rice Ltd" && (
                        <>
                          <InputGroup>
                            <Label htmlFor="balancec">
                              Balance Quantity Grade C(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="balancec"
                                id="balancec"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>

                          <InputGroup>
                            <Label htmlFor="balanced">
                              Balance Quantity Grade D(kg)
                            </Label>
                            <FieldWrapper>
                              <Field
                                type="number"
                                name="balanced"
                                id="balanced"
                                autoComplete="off"
                                className="truncate"
                                required
                              />
                            </FieldWrapper>
                          </InputGroup>
                        </>
                      )}
                    </CombineInputGridGroup>

                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="destination">Destination</Label>
                        <FieldWrapper>
                          <Field
                            name="destination"
                            id="destination"
                            type="text"
                            autoComplete="off"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="issueddate">Issued Date</Label>
                        <FieldWrapper>
                          <Field
                            type="date"
                            name="issueddate"
                            id="issueddate"
                            autoComplete="off"
                            className="truncate"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup> */}
                  </Wrapper>
                  <BtnWrapper>
                    <SubmitBtn type="submit" disabled={AddSubAgentLoading}>
                      {" "}
                      {AddSubAgentLoading ? (
                        <Loading
                          color="white"
                          width={20}
                          height={20}
                          noPadding={true}
                        />
                      ) : (
                        t("Add")
                      )}
                    </SubmitBtn>
                  </BtnWrapper>
                </Form>
              </>
            </Formik>
          </TextWrapper>
        </BoxContainer>
      </Bg>
    </>
  );
};

const TextWrapper = tw.div`h-full w-full flex justify-center`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const UpdateBtn = tw.button`
text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;

export default AddStockManagement;
