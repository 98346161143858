import React from "react";
import tw from "tailwind-styled-components";
import styled from "styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import moment from "moment";
import Model from "../Model";
import { useSelector } from "react-redux";
const EditFarmerModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  const user = useSelector((state) => state.UserReducer.user);

  const InitialValues = {
    quantitybought: selectedData?.quantityBrought,
    gradea: selectedData?.quantityGradeA,
    gradeb: selectedData?.quantityGradeB,
    gradec: selectedData?.quantityGradeC,
    graded: selectedData?.quantityGradeD,

    pricea: selectedData?.priceGradeA,
    priceb: selectedData?.priceGradeB,
    pricec: selectedData?.priceGradeC,
    priced: selectedData?.priceGradeD,

    moisture: selectedData?.moisture,
    id: selectedData?.id,

    recvdate: moment(selectedData?.receivingDate).format("YYYY-MM-DD"),
    cropType: selectedData?.cropType,
    cropName: selectedData?.cropName,
    variety: selectedData?.variety,
  };
  console.log(InitialValues)



  const SubmitHandler = (values) => editAddUser(values);


  return (
    <Model width={`w-11/12 max-w-3xl`} setOpenModel={setEditUser}>
      <Title>Edit Marketing </Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="cropType" >Crop Type</Label>
                  <FieldWrapper>
                    <Field name="cropName" id="cropType" type="text" autoComplete="off" required disabled />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="variety">Variety Type</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="variety"
                      placeholder="Variety Type"
                      id="variety"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>

                <InputGroup>
                  <Label htmlFor="moisture">Moisture</Label>
                  <FieldWrapper className="relative">
                    <Field
                      type="number"
                      name="moisture"
                      id="moisture"
                      autoComplete="off"
                      className="truncate pr-2"
                      required

                    />
                    <PercentageSign>%</PercentageSign>
                  </FieldWrapper>

                </InputGroup>
                <InputGroup>
                  <Label htmlFor="recvdate">Receiving Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="recvdate"
                      id="recvdate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Grade A (kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Grade B (kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                {user.companyName == "Kimolo Super Rice Ltd" && (
                  <>
                    <InputGroup>
                      <Label htmlFor="gradec">Quantity Grade C (kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="gradec"
                          id="gradec"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="graded">Quantity Grade D (kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="graded"
                          id="graded"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </>



                )}

              </CombineInputGroup>





              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="pricea">Price Grade A(Tsh/kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="pricea"
                      id="pricea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="priceb">Price Grade B(Tsh/kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="priceb"
                      id="priceb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                {user.companyName == "Kimolo Super Rice Ltd" && (
                  <>
                    <InputGroup>
                      <Label htmlFor="pricec">Price Grade C(Tsh/kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="pricec"
                          id="pricec"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="priced">Price Grade D(Tsh/kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="priced"
                          id="priced"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>



                  </>)}
              </CombineInputGroup>


            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;
const PercentageSign = styled.span`
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
`;
export default EditFarmerModel;
