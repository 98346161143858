import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Link, useNavigate } from "react-router-dom";
import Images from "../Images";
import {
  Page,
  BoxContainer,
  BoxTitle,
  Underline,
  Bg,
} from "../Components/Styles/PageStyles";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  InputGroup,
  FieldWrapper,
  Label,
  FormContainer,
  SubmitBtn,
  PreviewBtn,
  FormContainerVertical,
  CombineInputGroup,
} from "../Components/Styles/InputStyles.jsx";
import DropZone from "../Components/Dropzone";
import Loading from "../Components/Loading";
import { useMutation } from "react-query";
import Config from "../Config";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Update_User } from "../Redux/actions";
import SelectSearch from "react-select-search";
import "../select-search.css";
import Fuse from "fuse.js";
import ChangePasswordModal from "../Components/ChangePasswordModal";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../Components/Navbar";
import { MdPassword } from "react-icons/md";
import CustomPhoneInput from "../Components/CountryCode.jsx";
// import Loading from "react-loading";
const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [changePasswordModel, setChangePasswordModel] = useState(false);

  const user = useSelector((state) => state.UserReducer.user);

  // change password
  const changePasswordFunction = async (values) => {
    const body = new FormData();
    // body.append("oldPassword", values.oldPassword);
    body.append("email", user.email);
    body.append("id", user.userId);
    body.append("password", values.password);
    body.append("confirmPassword", values.confirmPassword);
    return await axios.post(Config.apiUrl + "/change-password", body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onChangePasswordSuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      //refetch();
      setChangePasswordModel(false);
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
  };

  const onChangePasswordError = (data) => {
    console.log("error", data?.data);
    toast.error(data?.data?.msg, "An Error Occured");
  };

  const { mutate: changePasswordMutate, isLoading: changePasswordLoading } =
    useMutation(changePasswordFunction, {
      onSuccess: onChangePasswordSuccess,
      onError: onChangePasswordError,
    });

  // update profile
  const updateProfileFunction = async (values) => {
    const body = new FormData();
    body.append("name", values.name);
    body.append("id", user.userId);
    body.append("email", values.email);
    body.append("phone", values.phone);
    body.append("userType", user.userType);
    // if (user.userType == 3) {
    //   const image = values?.userImage?.[0];
    //   if (image !== undefined) body.append("signature", values.userImage[0]);
    // }
    return await axios.post(Config.apiUrl + "/update-profile", body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (data) => {
    console.log("success", data?.data);
    toast.success(data?.data?.msg, "Success");
  };

  const onError = (data) => {
    console.log("error", data?.data);
    toast.error(data?.response?.data?.msg, "An Error Occured");
  };

  const { mutate: updateProfileMutate, isLoading: isLoading } = useMutation(
    updateProfileFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  const initialValues = {
    name: user.name || "",
    // lName: user.lname || "",
    email: user.email || "",
    phone: user.phone || "",
    //clubName: user.clubName || ""
  };

  const SubmitHandler = async (values) => {
    //setChangePasswordModel(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (values.email && !emailRegex.test(values.email)) {
      toast.error("Invalid email address");
      return;
    }
    updateProfileMutate(values);
  };

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          // height: "100vh",
        }}
      >
        <Navbar />
        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("my_profile")}</Title>
              <Underline />
            </div>
            <Uploader setChangePasswordModel={setChangePasswordModel} />
          </div>
          {changePasswordModel && (
            <ChangePasswordModal
              setChangePasswordModel={setChangePasswordModel}
              changePasswordLoading={changePasswordLoading}
              changePasswordMutate={changePasswordMutate}
            />
          )}

          <div className="mt-10">
            <Formik initialValues={initialValues} onSubmit={SubmitHandler}>
              {(formikProps) => (
                <Form>
                  <FormContainerVertical className=" w-full max-w-xl md:ml-10">
                    <InputGroup>
                      <Label htmlFor="email">{t("u_email")}</Label>
                      <FieldWrapper>
                        <Field
                          name="email"
                          type="email"
                          id="email"
                          placeholder=""
                          disabled
                        />
                      </FieldWrapper>
                    </InputGroup>
                    {/* <CombineInputGroup> */}
                    <InputGroup>
                      <Label htmlFor="name">Name</Label>
                      <FieldWrapper>
                        <Field
                          name="name"
                          type="text"
                          id="name"
                          placeholder="e.g. Hellan Johnson"
                        />
                      </FieldWrapper>
                    </InputGroup>
                    {/* <InputGroup>
                        <Label htmlFor="lName">{t("lname")}</Label>
                        <FieldWrapper>
                          <Field
                            name="lName"
                            type="text"
                            id="/Name"
                            placeholder="e.g. Hellan Johnson"
                          />
                        </FieldWrapper>
                      </InputGroup> */}
                    {/* </CombineInputGroup> */}

                    <InputGroup>
                      <Label htmlFor="phone">{t("u_m_number")}</Label>
                      <Field
                        name="phone"
                        render={({ field, form }) => (
                          <CustomPhoneInput
                            field={field}
                            form={form}
                            disabled={true}
                          />
                        )}
                      />
                      {/* // <FieldWrapper>
                      //   <Field
                      //     name="phone"
                      //     type="number"
                      //     id="phone"
                      //     placeholder=""
                      //     disabled
                      //   />
                      // </FieldWrapper> */}
                    </InputGroup>
                    {/* <InputGroup>
                    <Label htmlFor="phone">{t("a_name")}</Label>
                    <FieldWrapper>
                      <Field
                        name="clubName"
                        type="text"
                        id="clubName"
                        placeholder=""
                        disabled
                      />
                    </FieldWrapper>
                  </InputGroup> */}

                    {/* {user.userType == 3 && (
                      <InputGroup>
                        <Label htmlFor="signature">
                          Drop your Signature image
                        </Label>

                        <Field name="signature" id="signature" required>
                          {(props) => (
                            <DropZone
                              fields={props.field}
                              setFieldValue={props.form.setFieldValue}
                              componentFor="profile"
                            />
                          )}
                        </Field>
                        <p className="text-xs text-gray-500 mt-2.5 ">
                          Upload Image size (579px by 265px)
                        </p>
                      </InputGroup>
                    )} */}
                  </FormContainerVertical>
                  <SubmitBtnWrapper className="md:ml-10">
                    <div className="flex items-center space-x-10">
                      <SubmitBtn type="submit" disabled={isLoading}>
                        {isLoading ? (
                          <Loading
                            color="white"
                            width={20}
                            height={20}
                            noPadding={true}
                          />
                        ) : (
                          t("Update")
                        )}
                      </SubmitBtn>
                    </div>
                  </SubmitBtnWrapper>
                </Form>
              )}
            </Formik>
            {/* <PreviewBtn onClick={() => setChangePasswordModel(true)} className="text-sm md:text-sm">
                    {t("c_password")}
                  </PreviewBtn> */}
          </div>
        </BoxContainer>
        {/* </Page> */}
      </Bg>
    </>
  );
};

const Uploader = ({ setChangePasswordModel }) => {
  return (
    <UploadWrapper>
      <Button type="button" onClick={() => setChangePasswordModel(true)}>
        <MdPassword className="text-white w-5 h-5" /> <p>Change Password</p>
      </Button>
      {/* <Button type="button" onClick={() => setOpenUploadModel(true)}>
        <HiUpload className="text-white w-5 h-5" /> <p>Upload Bulk Contact</p>
      </Button> */}
    </UploadWrapper>
  );
};

const SubmitBtnWrapper = tw.div`
mt-10 `;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-custom-green hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;

export default Profile;
