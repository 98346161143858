import React from "react";
import tw from "tailwind-styled-components";
import styled from "styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
    InputGroup,
    FieldWrapper,
    Label,
    SubmitBtn,
    CombineInputGroup,
} from "../Styles/InputStyles";
import moment from "moment";
import Model from "../Model";
import { useSelector } from "react-redux";
const EditVehicleModel = ({
    editAddUser,
    editEntryLoading,
    setEditUser,
    selectedData,
}) => {
    const user = useSelector((state) => state.UserReducer.user);

    const InitialValues = {
        number: selectedData?.number,
        capacity: selectedData?.capacity,
        type: selectedData?.type,
        id: selectedData?.id,

    };
    console.log(InitialValues)



    const SubmitHandler = (values) => editAddUser(values);


    return (
        <Model width={`w-11/12 max-w-xl`} setOpenModel={setEditUser}>
            <Title>Edit Vehicle Detail </Title>

            {editEntryLoading && <Loading />}

            {!editEntryLoading && (
                <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
                    <Form>
                        <Wrapper>

                            <InputGroup>
                                <Label htmlFor="number">Vehicle Number</Label>
                                <FieldWrapper>
                                    <Field
                                        type="text"
                                        name="number"
                                        id="number"
                                        autoComplete="off"
                                        className="truncate"
                                        required
                                    />
                                </FieldWrapper>
                            </InputGroup>

                            <InputGroup>
                                <Label htmlFor="type">Vehicle Type</Label>
                                <FieldWrapper>
                                    <Field
                                        type="text"
                                        name="type"
                                        id="type"
                                        autoComplete="off"
                                        className="truncate"
                                        required
                                    />
                                </FieldWrapper>
                            </InputGroup>
                            <InputGroup>
                                <Label htmlFor="capacity">Vehicle Capacity (ton)</Label>
                                <FieldWrapper>
                                    <Field
                                        type="number"
                                        name="capacity"
                                        id="capacity"
                                        autoComplete="off"
                                        className="truncate"
                                        required
                                    />
                                </FieldWrapper>
                            </InputGroup>
                        </Wrapper>
                        <BtnWrapper>
                            <SubmitBtn type="submit">Update</SubmitBtn>
                        </BtnWrapper>
                    </Form>
                </Formik>
            )}
        </Model>
    );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;
const PercentageSign = styled.span`
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
`;
export default EditVehicleModel;
