import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import { toast } from "react-toastify";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";

import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import DropZone from "../Transportation/Dropzone"
import { useSelector } from "react-redux";
const AddTransportationModel = ({
  AddSubAgentLoading,
  postFarmerUser,

  setOpenUploadModel,

  data, vehicle,
  // companyArr,
}) => {
  const user = useSelector((state) => state.UserReducer.user);
  const { t, i18n } = useTranslation();
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const InitialValues = {

  };
  console.log(data)
  const SubmitHandler = (values) => {

    const cropTypeData = data?.find(
      (crop) => crop.cropType === values.cropType
    );
    console.log(cropTypeData);
    if (cropTypeData) {
      const sumGradeA = parseInt(cropTypeData.sumGradeA);
      const sumGradeB = parseInt(cropTypeData.sumGradeB);
      const sumGradeC = parseInt(cropTypeData.sumGradeC);
      const sumGradeD = parseInt(cropTypeData.sumGradeD);

      const issueGradeA = parseInt(values.gradea) || 0;
      const issueGradeB = parseInt(values.gradeb) || 0;
      const issueGradeC = parseInt(values.gradec) || 0;
      const issueGradeD = parseInt(values.graded) || 0;
      console.log(sumGradeA);
      console.log(issueGradeA);
      if (
        issueGradeA > sumGradeA ||
        issueGradeB > sumGradeB ||
        issueGradeC > sumGradeC ||
        issueGradeD > sumGradeD
      ) {
        toast.error(
          "Quantity Issued values cannot exceed the quantity received values."
        );
        return;
      }
    }

    if (selectedVehicle) {
      // If a vehicle is selected, set its values in the payload
      values.type = selectedVehicle.type;
      values.capacity = selectedVehicle.capacity;
    }
    postFarmerUser(values);
  };
  const cropList = data?.map((item) => ({
    value: item.cropType,
    label: item.cropName,
  }))
  const vehicleList = vehicle?.map((item) => ({
    value: item.id,
    label: item.number,
    type: item.type,
    capacity: item.capacity,
  }));
  return (
    <Model width={`w-11/12 max-w-3xl `} setOpenModel={setOpenUploadModel}>
      <Title>Issue Stock</Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="cropType">Crop Type</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="cropType" id="cropType" autoComplete="off"  >
                      {(props) => (
                        <Select
                          className="w-full h-full"

                          required
                          options={cropList}
                          classNamePrefix="select"

                          onChange={(val) => {
                            props.form.setFieldValue("cropType", val.value)
                          }
                          }

                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="variety">Variety Type</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="variety"
                      placeholder="Variety Type"
                      id="variety"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="issuedate">Issued Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="issuedate"
                      id="issuedate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>
              {/* <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="number">Vehicle Number</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="number"
                      id="number"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="type">Vehicle Type</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="type"
                      id="type"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="capacity">Vehicle Capacity(ton)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="capacity"
                      id="capacity"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup> */}
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="number">Vehicle Number</Label>
                  <FieldWrapper $select={true}>
                    <Field
                      required
                      name="number"
                      id="number"
                      autoComplete="off"
                    >
                      {(props) => (
                        <Select
                          className="w-full h-full"
                          required
                          options={vehicleList}
                          classNamePrefix="select"
                          // onChange={(val) => {
                          //   props.form.setFieldValue("type", val.value);
                          // }}
                          onChange={(val) => {
                            props.form.setFieldValue("number", val.value);
                            setSelectedVehicle(val);
                          }}
                        // onChange={(val) => {
                        //   const selectedVehicle = vehicleList.find(
                        //     (vehicle) => vehicle.value === val.value
                        //   );
                        //   props.form.setFieldValue("type", val.value);
                        //   setSelectedVehicle(selectedVehicle);
                        // }}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="type">Vehicle Type</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="type"
                      id="type"
                      autoComplete="off"
                      className="truncate"
                      required
                      value={
                        selectedVehicle ? selectedVehicle.type : ""
                      }
                      readOnly
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="capacity">Vehicle Capacity(ton)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="capacity"
                      id="capacity"
                      autoComplete="off"
                      className="truncate"
                      required
                      value={
                        selectedVehicle ? selectedVehicle.capacity : ""
                      }
                      readOnly
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              {/* <InputGroup>
                <Label htmlFor="capacity">Vehicle Capacity(ton)</Label>
                <FieldWrapper>
                  <Field
                    type="number"
                    name="capacity"
                    id="capacity"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup> */}

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Issue Grade A(kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Issue Grade B(kg)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                {user.companyName == "Kimolo Super Rice Ltd" && (
                  <>
                    <InputGroup>
                      <Label htmlFor="gradec">Quantity Issue Grade C(kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="gradec"
                          id="gradec"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="graded">Quantity Issue Grade D(kg)</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="graded"
                          id="graded"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                  </>)}

              </CombineInputGroup>



              <InputGroup>
                <Label htmlFor="documents">Delivery Note</Label>
                <FieldCustomWrapperDoc $select={true}>
                  <Field
                    name="documents"
                    type="text"
                    id="documents"
                    autoComplete="off"

                    required
                  >
                    {(props) => (
                      <DropZone
                        fields={props.field}
                        setFieldValue={props.form.setFieldValue}
                        componentFor="transportation"
                        acceptFiles="application/pdf"
                        File={props.field.value ? [props.field.value] : null}
                      />
                    )}
                  </Field>
                </FieldCustomWrapperDoc>
                <p className="text-xs text-gray-500 mt-2 ">
                  PDF max size: 10MB
                </p>
              </InputGroup>




            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;


const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const FieldCustomWrapperDoc = tw.div`
${(p) => (p.$select ? "" : "pl-2 field-wrapper ")}
relative  rounded  my-4 bg-gray-100   text-gray-800 items-center w-full`;

export default AddTransportationModel;
