import React, { useEffect, useState } from "react";
import Images from "../Images";
import tw from "tailwind-styled-components";
import Config from "../Config";
import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Save_User } from "../Redux/actions";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import Checkbox from "react-custom-checkbox";
import { BsCheckLg } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import Loading from "react-loading";
import {
  Page,
  BoxContainer,
  BoxTitle,
  Underline,
} from "../Components/Styles/PageStyles";
import {
  SubmitBtn,
  PreviewBtn,
  OtherBtn,
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
} from "../Components/Styles/InputStyles";
import { useTranslation } from "react-i18next";
import CustomPhoneInput from "../Components/CountryCode.jsx";
const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  const [showOTP, setShowOTP] = useState(false);

  // useEffect(() => {
  //   navigate("/register");
  // }, []);

  const InitialValue = {
    mobile: "",
    password: "",
  };

  //------- Send Register Request -------
  const postFunction = async (values) => {
    const body = new FormData();
    //setMobile (values.mobile);
    setEmail(values.phone);
    body.append("phone", values.phone);

    return await axios.post(`${Config.apiUrl}/forget-password`, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (res) => {
    //refetch();
    //setOpenUploadModel(false);
    //setShowOTP(true);
    if (res?.data?.status == "OK") setShowOTP(true);
    else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    //setOpenUploadModel(false);
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: postRegister } = useMutation(
    postFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
  //------- Send Register Request -------

  //------- Send OTP Request -------
  const OTPPostFunction = async (values) => {
    const body = new FormData();
    body.append("phone", values.phone);
    body.append("otp", values.otp);
    body.append("password", values.password);
    return await axios.post(`${Config.apiUrl}/resetpassword`, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onOTPSuccess = (res) => {
    if (res?.data?.status == "OK") {
      navigate("/");
    } else toast.error(res?.data?.msg || "Error");
  };

  const onOTPError = (res) => {
    //setOpenUploadModel(false);
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingOTP, mutate: postOTP } = useMutation(
    OTPPostFunction,
    {
      onSuccess: onOTPSuccess,
      onError: onOTPError,
    }
  );
  //------- Send OTP Request -------

  const SubmitHandler = (values) => {
    if (!showOTP) {
      //setShowOTP(true);
      if (!values.phone) {
        return toast.error("Please enter registered phone number");
      }
      //setShowOTP(true);
      postRegister({ ...values });
    }
    //otp flow
    else {
      if (values.password != values.reenter) {
        return toast.error("New & Confirm Password should be same");
      }
      postOTP({ ...values });
    }

    //runCampaignMutate({ ...values, isScheduled: isSendLater });
  };

  const handleLogin = () => {};

  return (
    <Login>
      {/* <ImageWrapper>
        <LoginImage src={Images.LoginImage2} alt="Login Image" />
      </ImageWrapper> */}
      <TextWrapperComp
        handleLogin={handleLogin}
        showOTP={showOTP}
        setShowOTP={setShowOTP}
        InitialValue={InitialValue}
        SubmitHandler={SubmitHandler}
        isLoadingReg={isLoadingReg}
        isLoadingOTP={isLoadingOTP}
        t={t}
      />
    </Login>
  );
};

const TextWrapperComp = ({
  handleLogin,
  InitialValue,
  SubmitHandler,
  showOTP,
  setShowOTP,
  isLoadingOTP,
  isLoadingReg,
  t,
}) => (
  <TextWrapper>
    <Container>
      {/* <TextOverlay>
        <img
          src={Images.NurseTextOverlay}
          alt="text-overlay"
          className="w-full h-full object-contain"
        />
      </TextOverlay> */}
      <Center>
        <div className="flex space-x-6">
          <Logo src={Images.PMSNewLogo} alt="logo" />
          {/* <VerticalBorder />
          <Logo src={Images.Logo} alt="logo" /> */}
        </div>
        <Title>{t("ForgotPassword")}</Title>
        {!showOTP && <SubTitle>{t("Reset_password")}</SubTitle>}
        {showOTP && <SubTitle>{t("sent_otp")}</SubTitle>}
        <Formik
          initialValues={InitialValue}
          onSubmit={SubmitHandler}

          // enableReinitialize={isInitialValueSaved}
        >
          {(formikProps) => (
            <>
              <Form>
                <FormContainer>
                  {!showOTP && (
                    <>
                      <InputGroup>
                        <Label htmlFor="phone">Phone Number</Label>
                        <Field
                          name="phone"
                          render={({ field, form }) => (
                            <CustomPhoneInput field={field} form={form} />
                          )}
                        />
                      </InputGroup>
                    </>
                  )}

                  {showOTP && (
                    <>
                      <InputGroup className="w-full">
                        <Label htmlFor="otp">{t("e_opt")}</Label>
                        <FieldWrapper>
                          <Field
                            type="number"
                            name="otp"
                            id="otp"
                            required
                            autoComplete="off"
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="password">{t("NewPassword")}</Label>
                        <FieldWrapper>
                          <Field
                            type="password"
                            name="password"
                            id="password"
                            required
                            autoComplete="off"
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="reenter">{t("ReenterPassword")}</Label>
                        <FieldWrapper>
                          <Field
                            type="password"
                            name="reenter"
                            id="reenter"
                            required
                            autoComplete="off"
                          />
                        </FieldWrapper>
                      </InputGroup>
                    </>
                  )}
                </FormContainer>

                <div className="mt-8">
                  {showOTP ? (
                    <SubmitBtn type="submit" disabled={isLoadingOTP}>
                      {isLoadingOTP && (
                        <Loading
                          color="white"
                          width={20}
                          height={20}
                          noPadding={true}
                        />
                      )}
                      {!isLoadingOTP && t("ChangePassword")}
                    </SubmitBtn>
                  ) : (
                    <SubmitBtn type="submit">
                      {" "}
                      {isLoadingReg && (
                        <Loading
                          color="white"
                          width={20}
                          height={20}
                          noPadding={true}
                        />
                      )}
                      {!isLoadingReg && t("Next")}
                    </SubmitBtn>
                  )}
                </div>
              </Form>
            </>
          )}
        </Formik>

        {/* <Link className="my-5 " to="/register">
          <p className="text-sm text-gray-500 pb-5 ">
            {t("no_account")}{" "}
            <span className="text-cyan-600 font-medium">{t("registar_u")}</span>
          </p>
        </Link> */}
      </Center>

      {/* <SmallText>
        Virtual health professionals to help with all your rapid testing needs.
      </SmallText> */}
    </Container>
  </TextWrapper>
);

const FormContainer = tw.div` grid max-w-sm  mt-5 w-full gap-5 md:gap-5`;
const Login = tw.section` flex  bg-white justify-center items-center`;
const Container = tw.div`flex flex-col h-full w-full justify-center items-start   `;
const Logo = tw.img`w-16  mb-6`;
//const TextWrapper = tw.div`w-full h-full  md:w-1/2 relative md:overflow-x-hidden overflow-y-auto md:pl-12`;
const TextWrapper = tw.div`h-full relative md:pl-12 w-3/4 md:1/2 lg:w-1/3 md:mt-40 lg:mt-40`;

const TextOverlay = tw.section`absolute -right-40 top-0 h-full  `;

const Title = tw.h1`text-xl sm:text-2xl font-light text-gray-800 tracking-wider`;

const SubTitle = tw.h3`mt-1 sm:mt-2 tracking-wider  text-gray-500 text-sm  `;

const Button = tw.button` mt-10 h-20 w-96  md:w-72 lg:w-96 px-5   text-gray-800 flex items-center border rounded-md bg-white z-10 loginBtn  `;

const ImageWrapper = tw.div`hidden h-full  md:block md:w-1/2  `;
const LoginImage = tw.img`h-full w-full object-cover object-right`;
const VerticalBorder = tw.div`w-0.5 h-14 bg-gray-300 mt-4`;
const Center = tw.div`h-full w-11/12    flex flex-col   `;
const SmallText = tw.p`text-xs font-normal text-gray-400 `;

export default ForgotPasswordPage;
