import React, { useEffect, useState } from "react";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import CustomPhoneInput from "../../Components/CountryCode";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
// import Select from "react-select";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import Navbar from "../../Components/Navbar";
import NotFoundModel from "../../Components/NotFoundModel";
// import Loading from "react-loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
  CombineInputGridGroup,
  CombineInputTwoGridGroup,
  SubmitBtn,
  CombineInputThreeGridGroup,
} from "../../Components/Styles/InputStyles";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
const EditIssueProcessedProduct = () => {
  const [cropName, setCropName] = useState("");
  const user = useSelector((state) => state.UserReducer.user);
  const cropNames =
    user?.cropName?.map((crop) => ({
      value: crop.id,
      label: crop.cropName,
    })) || [];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [isProductionFetched, setIsProductionFetched] = useState(false);
  const [productionLoading, setProductionLoading] = useState(true);
  const [error, setError] = useState(null);
  const EditProduct = async () => {
    try {
      const response = await axios.get(
        `${Config.apiUrl}/edit-issue-product/${id}`,
        {
          headers: {
            Authorization: "Bearer" + " " + user.token,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );

      return response.data; // Assuming your data is in response.data
    } catch (error) {
      // Handle errors here
      console.error("Error fetching production:", error);
      throw error;
    }
  };

  const {
    data,
    isLoading: queryLoading,
    error: queryError,
  } = useQuery("get-production", EditProduct, {
    enabled: !isProductionFetched,
    onSuccess: () => {
      setProductionLoading(false);
    },
    onError: (error) => {
      setError(error); // Set the error state
      setProductionLoading(false); // Set loading to false in case of an error
    },
  });

  console.log("Production Loading:", productionLoading);
  console.log("Query Loading:", queryLoading);
  console.log("Data:", data);

  useEffect(() => {
    if (data?.data && !isProductionFetched) {
      setIsProductionFetched(true);
    }
  }, [isProductionFetched, data]);
  //------- Edit Single Entry -------
  const EditFieldAgentFunction = async (values) => {
    const body = new FormData();
    body.append("productId", values.productId);
    if (user.companyName == "Kimolo Super Rice Ltd") {
      body.append("issuegradec", values.issuegradec);
      body.append("issuegraded", values.issuegraded);
    }
    body.append("number", values.number);
    body.append("vehicleId", values.vehicleId);
    body.append("issuegradea", values.issuegradea);
    body.append("issuegradeb", values.issuegradeb);
    body.append("capacity", values.capacity);
    body.append("buyerName", values.buyerName);

    body.append("gender", values.gender);
    body.append("phone", values.phone);
    body.append("receipt", values.receipt);
    body.append("buyerType", values.buyerType);
    body.append("variety", values.variety);
    body.append("destination", values.destination);

    body.append("issueDate", values.issueDate);
    body.append("id", id);
    body.append("companyId", user.companyId);

    return await axios.post(`${Config.apiUrl}/update-issue-product`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token, //token is not in inspect
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      navigate("/issue-product-list");
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
  };

  const onEditEntryError = (data) => {
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: editEntryLoading, mutate: editAddUser } = useMutation(
    EditFieldAgentFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );

  console.log(data?.data);

  const InitialValue = {
    number: data?.data?.vehicleNumber,
    type: data?.data?.type,
    vehicleId: data?.data?.vehicleId,
    vehiclename: data?.data?.vehiclename,
    vehicleId: data?.data?.vehicleId,
    capacity: data?.data?.vehiclecapacity,
    buyerName: data?.data?.buyerName,
    gender: data?.data?.gender,

    issueDate: moment(data?.data?.issueDate).format("YYYY-MM-DD"),
    issuegradeb: data?.data?.quantityissuedGradeB,
    issuegradea: data?.data?.quantityissuedGradeA,
    issuegradec: data?.data?.quantityissuedGradeC,
    issuegraded: data?.data?.quantityissuedGradeD,
    variety: data?.data?.variety,
    destination: data?.data?.destination,
    phone: data?.data?.phone,
    buyerType: data?.data?.buyerType,
    receipt: data?.data?.receipt,

    productId: data?.data?.productId,
    productName: data?.data?.productName,

    id: id,
  };
  console.log(InitialValue);
  const SubmitHandler = (values) => editAddUser({ ...values });
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        <BoxContainer>
          <div className="flex w-full justify-center items-center">
            <div>
              <Title>Edit Issue Processed Product</Title>
              <Underline />
            </div>
          </div>
          {productionLoading && <Loading />}

          {!productionLoading && (
            <TextWrapper>
              <Formik
                initialValues={InitialValue}
                onSubmit={SubmitHandler}
                enableReinitialize
              >
                <>
                  <Form>
                    <Wrapper>
                      <CombineInputThreeGridGroup>
                        <InputGroup>
                          <Label htmlFor="productId">Product Name</Label>
                          <FieldWrapper>
                            <Field
                              name="productName"
                              id="productId"
                              type="text"
                              autoComplete="off"
                              required
                              disabled
                            />
                          </FieldWrapper>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="variety">Variety Type</Label>
                          <FieldWrapper>
                            <Field
                              type="text"
                              name="variety"
                              placeholder="Variety Type"
                              id="variety"
                              autoComplete="off"
                              className="truncate"
                              required
                            />
                          </FieldWrapper>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="destination">Destination</Label>
                          <FieldWrapper>
                            <Field
                              name="destination"
                              id="destination"
                              type="text"
                              autoComplete="off"
                              required
                            />
                          </FieldWrapper>
                        </InputGroup>
                      </CombineInputThreeGridGroup>
                      <CombineInputGroup>
                        <InputGroup>
                          <Label htmlFor="issueDate">Issued Date</Label>
                          <FieldWrapper>
                            <Field
                              type="date"
                              name="issueDate"
                              id="issueDate"
                              autoComplete="off"
                              className="truncate"
                              required
                            />
                          </FieldWrapper>
                        </InputGroup>
                      </CombineInputGroup>

                      <CombineInputTwoGridGroup>
                        <InputGroup>
                          <Label htmlFor="issuegradea">
                            Quantity Issued Grade A(kg)
                          </Label>
                          <FieldWrapper>
                            <Field
                              type="number"
                              name="issuegradea"
                              id="issuegradea"
                              autoComplete="off"
                              className="truncate"
                              required
                              disabled
                            />
                          </FieldWrapper>
                        </InputGroup>

                        <InputGroup>
                          <Label htmlFor="issuegradeb">
                            Quantity Issued Grade B(kg)
                          </Label>
                          <FieldWrapper>
                            <Field
                              type="number"
                              name="issuegradeb"
                              id="issuegradeb"
                              autoComplete="off"
                              className="truncate"
                              required
                              disabled
                            />
                          </FieldWrapper>
                        </InputGroup>
                        {user.companyName == "Kimolo Super Rice Ltd" && (
                          <>
                            <InputGroup>
                              <Label htmlFor="issuegradec">
                                Quantity Issued Grade C(kg)
                              </Label>
                              <FieldWrapper>
                                <Field
                                  type="number"
                                  name="issuegradec"
                                  id="issuegradec"
                                  autoComplete="off"
                                  className="truncate"
                                  required
                                  disabled
                                />
                              </FieldWrapper>
                            </InputGroup>

                            <InputGroup>
                              <Label htmlFor="issuegradead">
                                Quantity Issued Grade D(kg)
                              </Label>
                              <FieldWrapper>
                                <Field
                                  type="number"
                                  name="issuegraded"
                                  id="issuegraded"
                                  autoComplete="off"
                                  className="truncate"
                                  required
                                  disabled
                                />
                              </FieldWrapper>
                            </InputGroup>
                          </>
                        )}
                      </CombineInputTwoGridGroup>

                      <CombineInputGroup>
                        <InputGroup>
                          <Label htmlFor="vehicleId">Vehicle Type</Label>
                          <FieldWrapper>
                            <Field
                              type="text"
                              name="vehiclename"
                              id="vehicleId"
                              autoComplete="off"
                              className="truncate"
                              required
                              disabled
                            />
                          </FieldWrapper>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="number">Vehicle Number</Label>
                          <FieldWrapper>
                            <Field
                              type="text"
                              name="number"
                              id="number"
                              autoComplete="off"
                              className="truncate"
                              required
                              disabled
                            />
                          </FieldWrapper>
                        </InputGroup>

                        <InputGroup>
                          <Label htmlFor="capacity">
                            Vehicle Capacity(ton)
                          </Label>
                          <FieldWrapper>
                            <Field
                              type="number"
                              name="capacity"
                              id="capacity"
                              autoComplete="off"
                              className="truncate"
                              required
                              disabled
                            />
                          </FieldWrapper>
                        </InputGroup>
                      </CombineInputGroup>
                      <CombineInputGroup>
                        <InputGroup>
                          <Label htmlFor="buyerName">Buyer Name</Label>
                          <FieldWrapper>
                            <Field
                              type="text"
                              name="buyerName"
                              id="buyerName"
                              autoComplete="off"
                              className="truncate"
                              required
                            />
                          </FieldWrapper>
                        </InputGroup>

                        <InputGroup>
                          <Label htmlFor="gender">Gender</Label>
                          <FieldWrapper $select={true}>
                            <Field
                              required
                              name="gender"
                              id="gender"
                              autoComplete="off"
                            >
                              {(props) => (
                                <Select
                                  className="w-full h-full pl-2"
                                  {...props.field}
                                >
                                  <MenuItem value="1">Male</MenuItem>
                                  <MenuItem value="2">Female</MenuItem>
                                </Select>
                              )}
                            </Field>
                          </FieldWrapper>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="phone">Phone Number</Label>
                          <Field
                            name="phone"
                            render={({ field, form }) => (
                              <CustomPhoneInput field={field} form={form} />
                            )}
                          />
                          {/* <FieldWrapper>
                    <Field
                      type="number"

                      maxlength={9}
                      name="phone"
                      id="phone"
                      autoComplete="off"
                      className="truncate"

                    />
                  </FieldWrapper> */}
                        </InputGroup>
                      </CombineInputGroup>
                      <CombineInputGroup>
                        <InputGroup>
                          <Label htmlFor="buyerType">Type of Buyer</Label>
                          <FieldWrapper $select={true}>
                            <Field
                              required
                              name="buyerType"
                              id="buyerType"
                              autoComplete="off"
                            >
                              {(props) => (
                                <Select
                                  className="w-full h-full pl-2"
                                  {...props.field}
                                >
                                  <MenuItem value="1">Consumer</MenuItem>
                                  <MenuItem value="2">Trade</MenuItem>
                                  <MenuItem value="3">In-House</MenuItem>
                                </Select>
                              )}
                            </Field>
                          </FieldWrapper>
                        </InputGroup>
                        <InputGroup>
                          <Label htmlFor="receipt">Receipt</Label>
                          <FieldWrapper>
                            <Field
                              type="text"
                              name="receipt"
                              id="receipt"
                              autoComplete="off"
                              className="truncate"
                              required
                            />
                          </FieldWrapper>
                        </InputGroup>
                      </CombineInputGroup>
                    </Wrapper>
                    <BtnWrapper>
                      <SubmitBtn type="submit" disabled={editEntryLoading}>
                        {" "}
                        {editEntryLoading ? (
                          <Loading
                            color="white"
                            width={20}
                            height={20}
                            noPadding={true}
                          />
                        ) : (
                          t("Update")
                        )}
                      </SubmitBtn>
                    </BtnWrapper>
                  </Form>
                </>
              </Formik>
            </TextWrapper>
          )}
          {/* {queryError && !productionLoading && <NotFoundModel />} */}
        </BoxContainer>
      </Bg>
    </>
  );
};

const TextWrapper = tw.div`h-full w-full flex justify-center`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const UpdateBtn = tw.button`
text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;

export default EditIssueProcessedProduct;
