import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title><MdViewHeadline className="mt-1" />View Details</Title>
        <SingleItem name={"Farmer Id"} value={selectedData?.id} />
        <SingleItem name={"Name"} value={selectedData?.name} />
        <SingleItem name={"Age"} value={selectedData?.age} />
        <SingleItem name={"Village"} value={selectedData?.village} />
        <SingleItem name={"District"} value={selectedData?.district} />
        <SingleItem name={"Phone Number"} value={selectedData?.phone} />
        <SingleItem name={"Field Agent"} value={selectedData?.fieldAgent} />
        <SingleItem name={"Created On"} value={moment(selectedData?.created_at).format("DD-MM-YYYY hh:mm A")} />
        <SingleItem name={"Updated On"} value={moment(selectedData?.updated_at).format("DD-MM-YYYY hh:mm A")} />
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    {<Value>{value}</Value>}
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;

export default ViewDetailModel;
