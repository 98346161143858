import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from "react-redux";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export function VerticleIssueStock({ data }) {
    const [filteredData, setFilteredData] = useState({});
    const user = useSelector((state) => state.UserReducer.user);
    useEffect(() => {
        if (data) {
            setFilteredData(data?.data?.data);
        }
    }, [data?.data?.data]);
    console.log(data?.data?.data)
    const labels = filteredData?.totalCropsTranspotation
        ? filteredData.totalCropsTranspotation.map((item) => item.name)
        : [];
    console.log(labels)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },

        },
    };


    const verticleData = {
        labels,
        datasets: [
            {
                label: 'Grade A',
                data: filteredData?.totalCropsTranspotation
                    ? filteredData.totalCropsTranspotation.map((item) => item.totalQuantityGradeA)
                    : [],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Grade B',
                data: filteredData?.totalCropsTranspotation
                    ? filteredData.totalCropsTranspotation.map((item) => item.totalQuantityGradeB)
                    : [],
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },]
    }
    if (user.companyName === "Kimolo Super Rice Ltd") {
        verticleData.datasets.push(
            {
                label: 'Grade C',
                data: filteredData?.totalCropsTranspotation
                    ? filteredData.totalCropsTranspotation.map((item) => item.totalQuantityGradeC)
                    : [],
                backgroundColor: 'rgba(255, 206, 86, 0.5)',
            },
            {
                label: 'Grade D',
                data: filteredData?.totalCropsTranspotation
                    ? filteredData.totalCropsTranspotation.map((item) => item.totalQuantityGradeD)
                    : [],
                backgroundColor: 'rgba(153, 102, 255, 0.5)',
            },
        )
    }


    return <Bar options={options} data={verticleData} />;
}
